import { Pipe, PipeTransform } from '@angular/core';

/* An issue arises when we use labels that have html code in it
 * we need to use innerHtml or similar ways to get the code to interpret
 * however whitespaces are converted to &nbsp; 
 * This in turn prevents natural linebreaks from css
 *
 * This pipe intends to reconvert the &nbsp; into breakable spaces and
 * allow dynamic breaks on the correct places depending on the users resolution
 * */

@Pipe({name: 'purgeInnerHtml'})
export class PurgeInnerHtmlPipe implements PipeTransform {
  transform(value: string, strToReplace: string, replacementStr: string): string {

    if(!value || ! strToReplace || ! replacementStr)
    {
      return value;
    }

 return value.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }
}
