import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}



// THIS HIDES ALL CONSOLE LOGS IN PROD
if(environment.production) {
   window.console.log = function(){};
}
//testing
if(!environment.production) {
   window.console.log = function(){};
}  

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
