import { Component,OnInit,Inject,HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../error-handler.service';
import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal,NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ChartLineModel } from '../../models/chart-line-model';
import { HomeService } from '../../core/middleware-call/home.service';
import { FundYieldModel } from '../../models/fund-yield-model';
import { PortfolioService } from '../../core/middleware-call/portfolio.service';
import { PortfolioDetailsModel } from '../../models/portfolio-details-model';
import { CurrencyPipe } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { AffService } from '../../core/middleware-call/aff.service';
import { InvestmentRepartitionProductModel } from '../../models/investment-repartition-product-model';

// Funds.Component = SECTION 3B - Plan View


@Component({
    selector: 'app-funds',
    templateUrl: './funds.component.html',
    styleUrls: ['./funds.component.scss']
})
export class FundsComponent implements OnInit {

    /////////////////////////////////////////////
    /// Declarations
    /////////////////////////////////////////////
    portfolioDetailsTable: PortfolioDetailsModel[];
    fundHistoryData: ChartLineModel[]; // popup line chart data
    fundYieldData: FundYieldModel[]; // popup table data

    riskScore: Number=null;
    riskProfileModalFundList: InvestmentRepartitionProductModel[];
    role: string;

    toolTipDate: Date;

    // Teamsoft rangesector approach - working
    visualRange: any={};

    // poc modal handling for prevention of multi call on multi modal openings
    isOpenWorking: boolean=false;

    isBusy: boolean; // behaviorable subject subscribed through headerSharingService, used to condition pointer on hover and click availablility if something else is in progress

    public innerWidth: any; // for mobile detection

    /////////////////////////////////////////////
    /// Constructor injections & onInit       ///
    /////////////////////////////////////////////

    constructor(
        private http: HttpClient,
        config: NgbModalConfig, // initial, by Julien? Why MODAL? (its not a popup)
        private modalService: NgbModal, // allows the function for modal popups to be called
        @Inject('BASE_URL') baseUrl: string,
        private router: Router,
        public translate: TranslateService,
        private toastr: ToastrService,
        private headerSharingService: HeaderSharingService,
        private portfolioService: PortfolioService,
        private homeService: HomeService,
        public errorHandler: ErrorHandlerService,
        private affService: AffService, // needed for affiliate version of funds where risk is shown
        private currencyPipe: CurrencyPipe) {
    }

    ngOnInit() {
        console.log("WALLET (section3) constructor");
        //sessionStorage.setItem("STORAGE_PLANVIEW_BUSY", "false");

        // TODO : ini portfolioDetailsTable before execuing this.gatherPortfolioDetailsTableData() to prevent false button disables
        this.gatherPortfolioDetailsTableData();

        this.headerSharingService.viewIsBusy.subscribe(value => {
            this.isBusy=value;
        })
        this.getHeaderSharingServiceRoleAndSetRiskForAffiliate()
        this.innerWidth=window.innerWidth; // for mobile detectiomn // should maybe be after-view-init

    }

    @HostListener('window:resize',['$event'])
    onResize(event) {
        this.innerWidth=window.innerWidth;
    }


    /////////////////////////////////////////////
    /// METHODS                               /// 
    /////////////////////////////////////////////

    gatherPortfolioDetailsTableData() {
        this.portfolioService.getPortfolioDetailsArray().subscribe(result => {
            console.log("gatherPortfolioDetailsTableData result");
            console.log(result);
            this.portfolioDetailsTable=result;
            // localStorage.setItem("PortfolioDetailsListLength", this.portfolioDetailsTable.length.toString()); // used to determin if section 3 buttons are available (in portfolio component .... procs false positives) // Deprecated, Replaced by devExReadableChartPortfolioDetailsPie?.length > 0

        },error => {
            console.error(error);
            console.error(error.status);
            if(error.status==500) {
                console.error("handle 500");

                this.errorHandler.handleError(error);
            }
        });
    }

    getFundValorisations(fndId) {
        return new Promise((resolve,reject) => {

            console.log("[2]  getFundModalDetails");
            // first clean the existing data so we dont show the wrong chart
            //this.fundHistoryLineData = null;

            // call the yield svc for Fund History data (JsonGetFundValorisations)
            this.homeService.getFundValorisations(fndId).subscribe(result => {
                console.log("[3]  GetFundValorisations result:");
                console.log(result);

                // preparing date setting for angular format compatibility
                let i=0;
                result.forEach(function(value) {
                    let d: Date=new Date(result[i].timeStamp*1000);
                    result[i].valueDate=d;
                    i++;
                });

                this.fundHistoryData=result;
                resolve('OK');

            },error => {
                console.error(error);
                console.error(error.status);
                    console.error("[3]  GetFundValorisations handle 500");
                    this.errorHandler.handleError(error);
                resolve('NOK');
            });
        });
    }

    getFundYield(fndId) {
        //amazon
        return new Promise((resolve,reject) => {
            /*
            console.log("[4]  GetFundYields");
            this.homeService.getFundYields(fndId).subscribe(result => {
                console.log("[5]  GetFundYields result:");
                console.log(result);

                this.fundYieldData=result;
               */ resolve('OK');/*

            },error => {
                console.error(error);
                console.error(error.status);
                if(error.status==500) {
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });*/
        });
    }




    /**
     * Make role subscribe to the HeaderSharingService
     * */
    getHeaderSharingServiceRoleAndSetRiskForAffiliate() {
        console.log("getHeaderSharingServiceRole");
        this.headerSharingService.role.subscribe(value => {
            this.role = value;
            if (value=="AFF"){
                //this.getRiskProfileScore(); // amazon
            }
            });

    }


    getRiskProfileScore() {
        this.affService.getRiskProfileScore().subscribe(result => {
            console.log("getRiskProfileScore result");
            console.log(result);
            this.riskScore=result;

        },error => {
            this.riskScore=0;
            //console.error(error);
            //console.error(error.status);
            //this.errorHandler.handleError(error);
        });
    }



    /**
     * allows to open modal popup on loupeclcik in section 3 (fund details)
     * @param content - holds the data needed to find the according modal code in the template
     * @param fndId - allows distinction for what modal data to gather from backend
     */
    open(content,fndId) {
        if(this.isBusy) { } else {
            this.headerSharingService.viewIsBusy.next(true);

            // First we close the currently opened modal if there is one
            this.modalService.dismissAll();
            if (this.isOpenWorking == false) {
                this.isOpenWorking = true;
                this.getFundValorisations(fndId).then(res => {

                    this.getFundYield(fndId).then(ress => {

                        if (Object.keys(this.fundHistoryData).length != 0) {
                            if (this.innerWidth < 1000)
                                this.modalService.open(content, { centered: true, windowClass: "mobileModalBoxSize", backdrop: true, keyboard: true });
                            else
                                this.modalService.open(content, { centered: true, size: 'lg', backdrop: true, keyboard: true });

                            this.isOpenWorking = false;

                            this.headerSharingService.viewIsBusy.next(false);
                        }
                        else {
                            this.isOpenWorking = false;
                            this.headerSharingService.viewIsBusy.next(false);
                            this.toastr.info(this.translate.instant('TS_No_Valorization_My_Funds_Info_Toast'));
                            return;
                        }
                    },
                        error => {
                            this.toastr.error(this.translate.instant('TS_Get_Fund_Data_Fail_Toast'), '');
                            this.headerSharingService.viewIsBusy.next(false);
                        });

                },
                    error => {
                        this.toastr.error(this.translate.instant('TS_Get_Fund_Data_Fail_Toast'), '');
                        this.headerSharingService.viewIsBusy.next(false);
                    });
            } else {
                this.headerSharingService.viewIsBusy.next(false);
            }
        } // end else busy
    }



    openRiskHelp(content) {
        let deactivated = true;

        if (!deactivated){
            console.log("openRiskHelp");
            if(this.isBusy) { 
                console.log("busy");
            } else {
                console.log("not busy");
                this.headerSharingService.viewIsBusy.next(true);

                // First we close the currently opened modal if there is one
                this.modalService.dismissAll();
                if(this.isOpenWorking==false) {
                    console.log("isOpenWorking false");
                    this.isOpenWorking=true;


                    if(this.riskProfileModalFundList==null) {
                        console.log("first riskProfileModalFundList get call");
                        this.affService.getRiskProfileFunds().subscribe(result => {
                            console.log("getRiskProfileFunds result");
                            console.log(result);
                            this.riskProfileModalFundList=result;
                            this.headerSharingService.viewIsBusy.next(false);
                            if(this.innerWidth<1000)
                                this.modalService.open(content,{ centered: true,windowClass: "mobileModalBoxSize",backdrop: true,keyboard: true });
                            else
                                this.modalService.open(content,{ centered: true,size: 'lg',backdrop: true,keyboard: true });
                            this.isOpenWorking=false;

                        },error => {
                            console.error(error);
                            console.error(error.status);
                            this.errorHandler.handleError(error);
                            this.isOpenWorking=false;
                            this.headerSharingService.viewIsBusy.next(false);
                        });
                    } else {
                        console.log("riskProfileModalFundList already set");
                        this.headerSharingService.viewIsBusy.next(false);
                        if(this.innerWidth<1000)
                            this.modalService.open(content,{ centered: true,windowClass: "mobileModalBoxSize",backdrop: true,keyboard: true });
                        else
                            this.modalService.open(content,{ centered: true,size: 'lg',backdrop: true,keyboard: true });
                        this.isOpenWorking=false;
                    }




                } else {
                    console.log("isOpenWorking true");
                    //this.headerSharingService.viewIsBusy.next(false);
                }
            } // end else busy
        }
    }

    ///////////////////////////////
    ///////// DEV EX CHART TOOLTIP FEEDER
    /////////////////////////////////////////////////////////////


    /*
      Get the index of currently hovered element to built a custom tooltip based on the data
      of the original data available in this.fundHistoryData, using the index as array pointer
    */
    customizeTooltip=(args: any) => {

        console.log("custom tooltip args logger");
        console.log(args);
        console.log(args.argument);

        // Currency pipe transforms arg.value into the right currency format
        args.value=this.currencyPipe.transform(args.value,"",this.fundHistoryData[0].fndCurCode,'1.2');
        this.toolTipDate=new Date(args.argument);

        let day=this.toolTipDate.getDate();
        let month=this.toolTipDate.getMonth()+1;
        let year=this.toolTipDate.getFullYear();

        return {
            html:
                "<div class='tooltipText'>"+args.value+"</div>"+
                "<div class='tooltipText'>"+day+"/"+month+"/"+year+"</div>"
        };
    }

    customizeRiskHelpTooltip=(args: any) => {
        console.log(" customizeRiskHelpTooltip args logger");
        console.log(args);
        return {
            html:
                "<div class='tooltipText'>"+args.value+"</div>"
        };
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    goToRp(){
        console.log(" goToRp");
        // routing is done via the routerlink in html
        // however we need to close this window
        this.modalService.dismissAll();
    }



    // reini chart visual/period selection by resetting the passed on range selector
    onDisposingRS(rs){
        console.log("disposing event: resetting range selector");
        rs.value=null;
    }
}
