
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core'; // TEST
import { ErrorHandlerService } from '../../error-handler.service';

import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { InvestmentRepartitionPieDataModel } from '../../models/investment-repartition-pie-data-model';
import { PortfolioService } from '../../core/middleware-call/portfolio.service';
import { SharedService } from '../../core/middleware-call/shared.service';
import { AffService } from '../../core/middleware-call/aff.service';

@Component({
    selector: 'app-investement-policy',
    templateUrl: './investement-policy.component.html',
    styleUrls: ['./investement-policy.component.scss']
})
export class InvestementPolicyComponent implements OnInit {

    /////////////////////////////////////////////
    /// Declarations
    /////////////////////////////////////////////

    devExReadableChartInvestmentRepartitionPie: InvestmentRepartitionPieDataModel[];
    isUserRH: boolean = null;
    isPortfolioReadOnly: boolean = true;

    isBusy: boolean; // behaviorable subject subscribed through headerSharingService, used to condition pointer on hover and click availablility if something else is in progress

    isPlanInMaintainanceBoolean: boolean; // if true there is no access to repartition
    /////////////////////////////////////////////
    /// Constructor injections & onInit       ///
    /////////////////////////////////////////////


    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private headerSharingService: HeaderSharingService,
        private portfolioService: PortfolioService,
        private affService: AffService,
        private sharedService: SharedService,
        public errorHandler: ErrorHandlerService) { }

    ngOnInit() {
        this.getIsPortfolioReadOnly();
        console.log("invest policy (section2) constructor");
        console.log("getting pie data from Portfolio ctrl GetInvestmentRepartitionProductList - connecting to EP: pf/JsonGetInv.R.P.L");
        this.gatherInvestmentRepartitionPieData();
        this.getRole();
        this.headerSharingService.viewIsBusy.subscribe(value => {
            this.isBusy = value;
        })

        // check if plan is in maintanance
        this.isPlanInMaintainance();
    }

    getRole() {
        this.sharedService.getRole().subscribe(result => {
            console.log("result of getRole in plan-view : " + result);
            if (result.role == "RH")
                this.isUserRH = true;
            else
                return false;
        });
    }

    /**
    * plan view constructor uses this to load the pie data for section 2's invest repartition pie chart
   * */

    // 1) What does the service initially produce?
    // array of PieData: defaultPerc = valueField? productName=argumentField string?
    // 2) What do pies use in testarea
    // 3) wherre will me map this?

    gatherInvestmentRepartitionPieData() {
        this.portfolioService.getNonNullInvestmentRepartitionProductArray().subscribe(result => {
            console.log("gatherInvestmentRepartitionPieData result");
            console.log(result);

            this.devExReadableChartInvestmentRepartitionPie = result;
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error("handle 500");

                this.errorHandler.handleError(error);
            }
        });
    }


    getIsPortfolioReadOnly() {
        this.portfolioService.isPortfolioReadOnly().subscribe(
            result => {
                this.isPortfolioReadOnly = result;
            });
    }

    goToRepartition() {
        if (this.isBusy){} else{

            this.headerSharingService.viewIsBusy.next(true);
            this.router.navigate(['/affiliate/repartitionRules']);
        }
    }

    // if this returns true it means the affiliate has quit the enterprise that issued the plan, but is still in the plan
    // this should prevent access to repartition / invest policy
    isPlanInMaintainance() {
        this.affService.isPlanInMaintainance().subscribe(result => {
            this.isPlanInMaintainanceBoolean = result;
            console.log("isPlanInMaintainanceBoolean: "+result);
        }, error => {
            console.error(error);
            if (error.status == 500) {
            }
        });
    }

    ///////////////////////////////
    ///////// DEV EX CHART TOOLTIP FEEDER
    /////////////////////////////////////////////////////////////

    customizeTooltip = (args: any) => {
        return {
            html:
                "<div class='tooltipText'>" + args.argument + " : " + args.percentText + "</div>" +
                "<div class='tooltipText'>ISIN: " + args.point.data.isinCode + "</div>"
            //+ "<div>" + args.point.data.numerator + "/" + args.point.data.denominator + "</div>"
        };
    }

}
