import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../error-handler.service';
import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { TranslateService } from '@ngx-translate/core'; // TEST
import { OperationModel } from '../../models/operation-model';
import { PeriodModel } from '../../models/period-model';
import { PortfolioService } from '../../core/middleware-call/portfolio.service';
import { HomeService } from '../../core/middleware-call/home.service';
import { PortfolioDetailsModel } from '../../models/portfolio-details-model';
import { SharedService } from '../../core/middleware-call/shared.service';
import { RoleResultModel } from '../../models/role-result-model';
import { PortfolioValorisationsChartModel } from '../../models/portfolio-valorisations-chart-model';
import { FundYieldModel } from '../../models/fund-yield-model';
import { CurrencyPipe } from '@angular/common';
//import { DxChartModule, DxChartComponent } from 'devextreme-angular';
import { DxChartComponent } from 'devextreme-angular';

// Wallet.Component = SECTION 3A - Plan View

@Component({
    selector: 'app-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss']
})
export class WalletComponent implements OnInit {


    activeIdString:any="guaranteed_rate_tab"; // used to auto switch tab oninit when first tab is empty
    /////////////////////////////////////////////
    /// Declarations
    /////////////////////////////////////////////
    devExReadableChartPortfolioDetailsPie: PortfolioDetailsModel[];
    periodsList: PeriodModel[];
    operationList: OperationModel[];

    operationListGuaranteedRateProductLength : number = 0;
    showGuaranteedRateProductEmptyMessage : boolean = false;
    operationListNotGuaranteedRateProductLength : number = 0;
    showNotGuaranteedRateProductEmptyMessage : boolean = false;

    operationsButtonAvailable: false;
    role: RoleResultModel = null;
    isUserRH: boolean = null; // conditions if button is accessible
    isPortfolioReadOnly: boolean = true; // init at rue to prevent flashing
    portfolioValorisation: PortfolioValorisationsChartModel[];
    // portfolioYields: FundYieldModel[]; // amazon

    @ViewChild('targetPFValo') targetPFValo: DxChartComponent;
    // conditioners for buttons
    //portfolioDetailsListLength: number; // Deprecated - replaced by devExReadableChartPortfolioDetailsPie?.length > 0

    toolTipDate: Date;

    tsPalette: ['#bd3aa1', '#a63db8', '#d4388a', '#eb3573', '#1db2f5', '#6593bc', '#ad7583', '#f5564a', '#de734f', '#c69053', '#afac58', '#97c95c', '#b1c94d', '#cbc83e', '#e5c82f', '#ffc720', '#faa335', '#f57e4a', '#f05a5e', '#6278d7'];

    selectedValue: Number = 0; // for getAvailableExercisePeriods ?? Determins the year for an exercise ?
    selectedValueAsPeriodString: String = ""; // A period can be independant from 1.January to 31December, so using as number is not good enough

    public innerWidth: any; // for mobile detection
    public dxPortfolioModalOver800pxAdaptiveWidth: any; // for bugged devexpress chart in mobile/desktop detection, with portrait-landslide switches - over 800px where half of the modal uses a pie
    public dxPortfolioModalUnder800pxAdaptiveWidth: any; // for bugged devexpress chart in mobile/desktop detection, with portrait-landslide switches - under 800px where only the chart exists (no pie)


    isBusy: boolean; // behaviorable subject subscribed through headerSharingService, used to condition pointer on hover and click availablility if something else is in progress
    portfolioValorisationNoData: boolean;

    //dataChart1: DxChartComponent;
    //@ViewChild("targetPFValo") dataChart1: DxChartComponent
    // use: // this.dataChart1.instance.refresh();
    //    dataChart1Instance: DxChartComponent; // used to store instance of widget on init
    // html: // (onInitialized)="getDxChartToDebugDevExpressShit($event)


    /////////////////////////////////////////////
    /// Constructor injections & onInit       ///
    /////////////////////////////////////////////

    constructor(
        private modalService: NgbModal, // allows the function for modal popups to be called
        @Inject('BASE_URL') baseUrl: string,
        private router: Router,
        public translate: TranslateService,
        private headerSharingService: HeaderSharingService,
        private portfolioService: PortfolioService,
        private sharedService: SharedService,
        private homeService: HomeService,
        public errorHandler: ErrorHandlerService,
        private currencyPipe: CurrencyPipe) { }

    ngOnInit() {
        console.log("WALLET (section3) constructor");
        this.getRole();
        this.gatherPortfolioDetailsPieData();
        this.getAvailableExercisePeriods();
        this.getOperationsData();

        this.getIsPortfolioReadOnly();

        //this.portfolioDetailsListLength = +localStorage.getItem("PortfolioDetailsListLength"); // cast to number // user for button conditioning // deprecated, replaced by : devExReadableChartPortfolioDetailsPie?.length > 0
        this.getPortfolioValorisations();
        //this.getPortfolioYields(); // amazon
        this.headerSharingService.viewIsBusy.subscribe(value => {
            this.isBusy = value;
        })
        this.innerWidth = window.innerWidth; // for mobile detectiomn // should maybe be after-view-init
        this.dxPortfolioModalOver800pxAdaptiveWidth= this.innerWidth - (this.innerWidth/2) -70;
            this.dxPortfolioModalUnder800pxAdaptiveWidth= this.innerWidth - (this.innerWidth/10);
    }

    ngAfterViewInit() {
        console.log("    ngAfterViewInit inner");
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // magic to prevent false parasite innerWidths to be set that seem to emerge from the warp
        setTimeout(()=> {
            this.innerWidth = window.innerWidth;
            this.dxPortfolioModalOver800pxAdaptiveWidth= this.innerWidth - (this.innerWidth/2) -70;
            this.dxPortfolioModalUnder800pxAdaptiveWidth= this.innerWidth - (this.innerWidth/10);
            console.log("updated innerWidth"+ this.innerWidth + "  // over 800:" +this.dxPortfolioModalOver800pxAdaptiveWidth + "  // under 800:"+this.dxPortfolioModalUnder800pxAdaptiveWidth);

        }, 0);
    }

    /////////////////////////////////////////////
    /// METHODS                               ///
    /////////////////////////////////////////////

    getPortfolioValorisations() {
        this.portfolioService.getPortfolioValorisations().subscribe(result => {
let poc = true;
            if (result.length> 0 && !poc)
                this.portfolioValorisation = result;
            else {
                this.portfolioValorisationNoData = true;
            }
        });
    }


    // amazon
    //getPortfolioYields() {
    //    this.portfolioService.getPortfolioYields().subscribe(result => {
    //        this.portfolioYields = result;
    //        console.log(this.portfolioYields);
    //    });
    //}

    getIsPortfolioReadOnly() {
        this.portfolioService.isPortfolioReadOnly().subscribe(
            result => {
                this.isPortfolioReadOnly = result;
                console.log("isPortfolioReadOnly: "+result);
            });
    }

    getRole() {
        this.sharedService.getRole().subscribe(result => {
            console.log("result of getRole in plan-view : " + result);
            this.role = result;
            if (this.role.role == "RH")
                this.isUserRH = true;
            else
                return false;
        });
    }

    /* Dx Debug */

    getDxLineChartToDebugDevExpress(e) {
        ////e.size.width;
        // e.instance.forceRender();
        // e.instance.refresh();
        // e.instance.size.width(500);
    }

    getDxPieChartToDebugDevExpress(e) {
        //e.series[0].color = "orange";

    }

    onDrawnLineChart(c){
        //this.targetPFValo = c;
        //this.targetPFValo. = c;
        //this.targetPFValo.size.width(200);
        //this.targetPFValo.instance.beginUpdate();
        //this.targetPFValo.instance.refresh();
        //this.targetPFValo.instance.render();
        //c.redraw();
        //c.render();
    };


    /**
    * plan view constructor uses this to load the pie data for section 3's porfolio pie chart
    * */

    gatherPortfolioDetailsPieData() {
        console.log("gatherPortfolioDetailsPieDat");
        this.portfolioService.getNonNullPortfolioDetailsArray().subscribe(result => {
            console.log("GetPortfolioDetailsArray result **************-----------");
            console.log(result);
            if (result.length > 0)
                console.log(result);
            this.devExReadableChartPortfolioDetailsPie = result;
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error("handle 500");
                this.errorHandler.handleError(error);
            }
        });
    }

    // gets initial data for the operations modal
    getOperationsData() {
        this.showNotGuaranteedRateProductEmptyMessage = false; // reini to false to not flash empty msg on change
        this.showGuaranteedRateProductEmptyMessage = false; // reini to false to not flash empty msg on change
        // get the new data
        this.homeService.getOperations().subscribe(result => {
            console.log("GetOperations result");
            console.log(result);
            this.operationList = result;
            for (let i=0; i < result.length; i++){
                if (result[i].isGuaranteedRateProduct)
                    {this.operationListGuaranteedRateProductLength++;}
                else
                    {this.operationListNotGuaranteedRateProductLength++;}
            }
            if (this.operationListNotGuaranteedRateProductLength==0){this.showNotGuaranteedRateProductEmptyMessage = true;}
            if (this.operationListGuaranteedRateProductLength==0){this.showGuaranteedRateProductEmptyMessage =true;}
            // in case there are no ops in tab 1 (guaranteed_rate_tab) and there are ops in tab 2 (iventment_funds-tab), then perform a tab switch
            if (this.operationListGuaranteedRateProductLength==0 && this.operationListNotGuaranteedRateProductLength>0){this.activeIdString="investment_funds_tab"}


        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error("handle 500");
                this.errorHandler.handleError(error);
            }
        });
    }

    // gets available periods for the dropdown in the operations modal
    getAvailableExercisePeriods() {
        this.homeService.getAvailableExercisePeriods().subscribe(result => {
            console.log("GetAvailableExercisePeriods (JsonGetExercises) result");
            console.log(result);
            this.periodsList = result;
            // initialize the period title value ( period zero is loaded by default and is the most recent year )
            if(this.selectedValue==0)
            {
                let x : Date = new Date(this.periodsList[0].beginDate);
                this.selectedValue = x.getFullYear();
            }
            if(this.selectedValueAsPeriodString=="")
            {
                let x : Date = new Date(this.periodsList[0].beginDate);
                let y : Date = new Date(this.periodsList[0].endDate);
                let xMonth = x.getMonth()+1;
                let yMonth = y.getMonth()+1;
                this.selectedValueAsPeriodString = x.getDate()+"/"+xMonth+"/"+x.getFullYear()+ " - " + y.getDate()+"/"+yMonth+"/"+y.getFullYear();
            }
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error("handle 500");
                this.errorHandler.handleError(error);
            }
        });
    }

    // loads another operations exercise based on the period parameter clicked in the operations modal dropdown
    loadOperationsExerciseByPeriod(exeId) {
        this.showNotGuaranteedRateProductEmptyMessage = false; // reini to false to not flash empty msg on change
        this.showGuaranteedRateProductEmptyMessage = false; // reini to false to not flash empty msg on change

        // reini the counters for empty message
        this.operationListGuaranteedRateProductLength = 0;
        this.operationListNotGuaranteedRateProductLength = 0;
        this.homeService.getOperationsByPeriod(exeId).subscribe(result => {
            console.log("overloadOperationsData result");
            console.log(result);
            this.operationList = result;
            for (let i=0; i < result.length; i++){
                if (result[i].isGuaranteedRateProduct)
                    {this.operationListGuaranteedRateProductLength++;}
                else
                    {this.operationListNotGuaranteedRateProductLength++;}
            }
            if (this.operationListNotGuaranteedRateProductLength==0){this.showNotGuaranteedRateProductEmptyMessage = true}
            if (this.operationListGuaranteedRateProductLength==0){this.showGuaranteedRateProductEmptyMessage =true;}

            // in case there are no ops in tab 1 (guaranteed_rate_tab) and there are ops in tab 2 (iventment_funds-tab), then perform a tab switch
            if (this.operationListGuaranteedRateProductLength==0 && this.operationListNotGuaranteedRateProductLength>0){this.activeIdString="investment_funds_tab"}
            // also vice versa, as this occurs on period select. User could be on tab 2
            if (this.operationListNotGuaranteedRateProductLength==0 && this.operationListGuaranteedRateProductLength>0){this.activeIdString="guaranteed_rate_tab"}
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error("handle 500");
                this.errorHandler.handleError(error);
            }
        });
    }

    setSelectedValue(dateX, dateY){
        let x : Date = new Date(dateX);
        let y : Date = new Date(dateY);
        let xMonth = x.getMonth()+1;
        let yMonth = y.getMonth()+1;
        this.selectedValueAsPeriodString = x.getDate()+"/"+xMonth+"/"+x.getFullYear()+ " - " + y.getDate()+"/"+yMonth+"/"+y.getFullYear();
    }

    goToSwitch() {
        if (this.isBusy){} else{
            this.headerSharingService.viewIsBusy.next(true);
            this.router.navigate(['/affiliate/switchRules']);
        }
    }

    ///////////////////////////////
    ///////// POPUP MECHANICS
    ///////////////////////////////

    openVerticallyCentered(content) {
        // First we close the currently opened modal if there is one
        this.modalService.dismissAll();
        // if mobile - we dont wont the invading right borde
        if (this.innerWidth<800)
            this.modalService.open(content, { centered: true, windowClass: "mobileModalBoxSize", backdrop: true , keyboard:true });
        else 
            this.modalService.open(content, { centered: true, windowClass: "myCustomModalClass", backdrop: true , keyboard:true });
    }
    openVerticallyCenteredPortfolioDetails(content) {
        // First we close the currently opened modal if there is one
        this.modalService.dismissAll();
        // if mobile - we dont wont the invading right borde
        if (this.innerWidth<800 || this.portfolioValorisationNoData == false)
            this.modalService.open(content, { centered: true, windowClass: "mobileModalBoxSize", backdrop: true , keyboard:true });
        else 
            this.modalService.open(content, { centered: true, windowClass: "myCustomModalClass", backdrop: true , keyboard:true });
    }


    closeModal() {
        this.modalService.dismissAll();
    }



    ///////////////////////////////
    ///////// DEV EX CHART TOOLTIP FEEDER
    /////////////////////////////////////////////////////////////

    customizeTooltipPie = (args: any) => {

        let displayedValue: string = this.currencyPipe.transform(args.point.data.schemeTotalUnitAmount, "", args.point.data.schCurCode, '1.2');
        this.toolTipDate = new Date(args.point.data.navDate);

        let day = this.toolTipDate.getDate();
        let month = this.toolTipDate.getMonth()+1;
        let year = this.toolTipDate.getFullYear();

        var thisPerc = (args.percent * 100).toFixed(2);
                //"<div class='tooltipText'><h3 style='font-size:16px;'>" + args.argument + " : " + args.percentText + "</h3></div>" +
        return {
            html:
                "<div class='tooltipText'><h3 style='font-size:16px;'>" + args.argument + " : " + thisPerc + " %</h3></div>" +
                "<div class='tooltipText'>ISIN: " + args.point.data.isinCode + "</div>" +
                "<div class='tooltipText'>" + displayedValue + "</div>" +
                "<div class='tooltipText'>" + day + "/" + month + "/" + year + "</div>"
        };
    }

    customizeTooltipLineChart = (args: any) => {

        let displayedValue: string = this.currencyPipe.transform(args.originalValue, "", args.point.aggregationInfo.data[0].schemeCurCode, '1.2');
        this.toolTipDate = new Date(args.argument);

        let day = this.toolTipDate.getDate();
        let month = this.toolTipDate.getMonth()+1;
        let year = this.toolTipDate.getFullYear();

        var thisPerc = (args.percent * 100).toFixed(2);
        return {
            html:
                "<div class='tooltipText'>" + displayedValue + "</div>" +
                "<div class='tooltipText'>" + day + "/" + month + "/" + year + "</div>"
        };
    }

    // reini chart visual/period selection by resetting the passed on range selector
    onDisposingRS(rs){
        console.log("disposing event: resetting range selector");
        rs.value=null;
    }
}
