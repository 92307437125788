import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticateModel } from '../../models/authenticate-model';
import { AuthenticateResultModel } from '../../models/authenticate-result-model';
import { ValidityResultModel } from '../../models/validity-result-model';

@Injectable()
export class LoginService {

  constructor(private http: HttpClient) {

  }

  logIn(authenticate: AuthenticateModel) {
      return this.http.post<AuthenticateResultModel>(/*this.baseUrl +*/ 'api/Login/Login', authenticate);
  }

  /***
  * Checks if the conditions needed to log into home page are met
  * */
  initValidity() {
      return this.http.get<ValidityResultModel>('api/Login/GetUserConditions');
  }

  /***
    *  Debug function that corrupts session token
    * */
  corruptMySession() {
      return this.http.get<string>('api/Login/CorruptMySession');
  }

  /***
   * Checks if the current token is still valid
   * */
  isTokenValid() {
    return this.http.get<boolean>('api/Login/isTokenValid');
  }

}
