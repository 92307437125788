import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FatcaModel } from '../../models/fatca-model';
import { AffiliateSchemeResultModel } from '../../models/afffiliate-schemes-result-model';
import { RegisterEmailModel } from '../../models/register-email-model';
import { RiskProfilePastChoicesResultModel } from '../../models/risk-profile-past-choices-result-model';
import { InvestmentRepartitionProductModel } from '../../models/investment-repartition-product-model';


@Injectable()
export class AffService {

    // for sidebar optimizing
    editableAffiliateSchemesSwitch: AffiliateSchemeResultModel[] = [];
    editableAffiliateSchemesRepartition: AffiliateSchemeResultModel[] = [];
    schemes: AffiliateSchemeResultModel[] = [];


    constructor(private http: HttpClient) {

    }

    setSwitchSchemes(x) {this.editableAffiliateSchemesSwitch=x;}
    getSwitchSchemes() {return this.editableAffiliateSchemesSwitch;}

    setRepartitionSchemes(x) { this.editableAffiliateSchemesRepartition=x;}
    getRepartitionSchemes() {return this.editableAffiliateSchemesRepartition;}

    setSchemes(x){this.schemes=x;}
    getSchemes(){return this.schemes;}


    isSidebarOptimizerUsable () {
        if (this.editableAffiliateSchemesSwitch.length > 0 && this.editableAffiliateSchemesRepartition.length > 0)
            return true;
        else
            return false;
    }

    getFatca() {
        return this.http.get<FatcaModel>('api/Aff/GetFatcaData');
    }

    saveFatca(fatcaModel: FatcaModel) {
        return this.http.post<FatcaModel>('api/Aff/SaveFatcaData', fatcaModel)
    }

    getCurrentAffiliateSchemeID() {
        return this.http.get<string>('api/Aff/GetCurrentAffiliateSchemeID');
    }

    getAffiliateSchemes() {
        return this.http.get<AffiliateSchemeResultModel[]>('api/Aff/GetAffiliateSchemes');
    }

    userConditionsAreFine() {
        return this.http.get<boolean>('api/Aff/UserConditionsAreFine');
    }
    getNumberOfSchemes() {
        return this.http.get<number>('api/Aff/NumberOfSchemes');
    }

    //old
    //setEmailAddress(register: string) {
    //    return this.http.post<string>('api/Aff/SetEmailAddress', register);
    //}

    //new
    setEmailAddress(registerEmail: RegisterEmailModel) {
        return this.http.post<any>('api/Aff/SetEmailAddress', registerEmail);
    }

    // This does not respect the specs from UML! This should point to shared as its used by RH also
    acceptCondition() {
        console.log("DEPRECATED 'acceptCondition' call");
        return this.http.post<any>('api/Aff/AcceptConditions', null);
    }

    acceptInvestmentFinancialRisk() {
        return this.http.get('api/Switch/AcceptInvestmentFinancialRisk' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    acceptSwitchFinancialRisk() {
        return this.http.get('api/Switch/AcceptSwitchFinancialRisk' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    isRiskProfileAvailable() {
        return this.http.get<boolean>('api/Aff/IsRiskProfileAvailable');
    }

    getRiskProfilePastChoices() {
        return this.http.get<RiskProfilePastChoicesResultModel>('api/Aff/GetRiskProfilePastChoices');
    }
    
    saveRiskProfile(riskProfileNgModel: Number[]) {
        return this.http.post<any>('api/Aff/SaveRiskProfile',riskProfileNgModel);
    }
    
    getRiskProfileScore() {
        return this.http.get<number>('api/Aff/GetRiskProfileScore');
    }

    // backend recycles another model for this service, so front must add conditionings to every other call to this model where riskLevel is not present...
    getRiskProfileFunds() {
        return this.http.get<InvestmentRepartitionProductModel[]>('api/Aff/GetRiskProfileFunds' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    isPlanInMaintainance() {
        return this.http.get<any>('api/Aff/IsPlanInMaintainance' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    isPlanInMaintainanceForSpecificPlan(affid) {
        return this.http.get<boolean>('api/Aff/IsPlanInMaintainance' + "?affId=" + affid);
    }


    generateAccountStatement(affId: number) {
        const url = 'api/Aff/GenerateAccountStatement' + '?affId=' + affId;
        return this.http.get(url, { responseType: 'blob' });

    }




}
