import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompaniesModel } from '../../models/companies-model';
import { CompanySchemeModel } from '../../models/company-scheme-model';
import { AffiliateModel } from '../../models/affiliate-model';
import { GenerateLatestIndividualCertificatesModel } from '../../models/generate-latest-individual-certificates-model';
import { LeaverModel } from '../../models/leaver-model';
import { NotifyLeaverModel } from '../../models/notify-leaver-model';
import { Observable } from 'rxjs';

@Injectable()
export class RHService {

    constructor(private http: HttpClient) {

    }

    // Get all companies if name equals null or get companies corresponding to the name
    getCompanies(name) {
        if (name == "")
            return this.http.get<CompaniesModel[]>('api/Rh/GetCompanies');
        else
            return this.http.get<CompaniesModel[]>('api/Rh/GetCompanies?searchString=' + name);
    }


    // Get company schemes corresponding to a company id
    getCompanySchemes(cieId) {
        return this.http.get<CompanySchemeModel[]>('api/Rh/GetCompanySchemes?companyId=' + cieId);
    }

    searchAffiliate(planSelected: number, name: string) {
        return this.http.get<AffiliateModel[]>('api/Rh/SearchAffiliates?companySchemeId=' + planSelected + "&searchString=" + name);
    }

    //searchAffiliation(Number(sessionStorage.getItem('companyId')): number, name: string) {
    searchAffiliation(name: string) {
        return this.http.get<any[]>('api/Rh/SearchAffiliation?companyId=' + Number(sessionStorage.getItem('companyId')) + "&searchString=" + name);
    }

    // get the documents available to this company
    getRhDocuments(comapanySchemeId: number) {
        return this.http.get<any[]>('api/Rh/GetRhDocuments?companySchemeId=' + comapanySchemeId);
    }


    // TO BE TESTED FROM HERE


    // used to determin if the Report follow up generation should be available or not in rh home actions rapides
    getIsInvestmentFollowUpVisible(companySchemeId: number) {
        return this.http.get<any>('api/Rh/IsInvestmentFollowUpVisible?cshId=' + companySchemeId);
    }

    // get the last available exeId of a given company's plan
    // used to give the template the possibility to generate certificates by using the exeId on the affiliate row print button and on multiple generations
    getExeId(companySchemeId: number) {
        return this.http.get<any>('api/Rh/GetExeId?cshId=' + companySchemeId);
    }


    // gets the latest exeId for the company with cshId, and then gets the certificate for the user with that affId, and sends back the PDF as blob stream
    generateLatestIndividualCertificate(affId: number, cshId: number) {
        console.log("rh svc: generateLatestIndividualCertificate");
        const url = 'api/Rh/GenerateLatestIndividualCertificate' + "?affId=" + affId + "&cshId="+cshId;
        return this.http.get(url, { responseType: 'blob' });
    }

    generateLatestIndividualCertificates(individualCertificatesGeneratorModel: GenerateLatestIndividualCertificatesModel) {
        //return this.http.get<any>('api/Rh/GenerateLatestIndividualCertificates?genCertifs=' + individualCertificatesGeneratorModel);
        console.log(individualCertificatesGeneratorModel);
        return this.http.post<any>('api/Rh/GenerateLatestIndividualCertificates', individualCertificatesGeneratorModel);
    }

    deleteSchemeDocument(sdcId: number) {
        console.log(sdcId);
        return this.http.post<any>('api/Rh/DeleteSchemeDocument', sdcId);
    }

    isRhAndNotAdmin() {
        return this.http.get<any>('api/Rh/IsRhAndNotAdmin');
    }

    // Demande de Rapport
    generateInvestmentFollowup(companySchemeId: number){
        return this.http.post<any>('api/Rh/GenerateInvestmentFollowup', companySchemeId);
    }

    generatePremiumReport(companySchemeId: number){
        return this.http.post<any>('api/Rh/GeneratePremiumReport', companySchemeId);
    }

    // Get all employees with leaver info
    searchAffiliatesWithLeaversInfos(cieId: number) {
        //return this.http.get<any>('api/Rh/SearchAffiliatesWithLeaversInfos?companyId=' + cieId + "&searchString=" + "");
        return this.http.get<NotifyLeaverModel[]>('api/Rh/SearchAffiliatesWithLeaversInfos?companyId=' + cieId + "&searchString=" + "");
    }

    // signal leaves
    declareLeavers(leavers : LeaverModel[]) {
        return this.http.post<any>('api/Rh/DeclareLeavers', {"leavers":leavers,"companyId":Number(sessionStorage.getItem('companyId'))});
    }

    // get leave reasons
    getLeaveReasons() {
        return this.http.get<any>('api/Rh/GetLeaveReasons');
    }

    deleteLeaverNotification(empId : number) {
        return this.http.post<any>('api/Rh/DeleteLeaverNotification', empId);
    }

    // Basically get the affiliate's profile infos
    getAffiliateInformation(affId: number) {
        return this.http.get<any>('api/Rh/GetAffiliateInformation?affId=' + affId);
    }

    generateAccountStatement(affId: number) {
        const url = 'api/Rh/GenerateAccountStatement' + '?affId=' + affId;
        return this.http.get(url, { responseType: 'blob' });

    }

    //postFile(fileToUpload: File): Observable<boolean> {
    //    const endpoint = 'your-destination-url';
    //    const formData: FormData = new FormData();
    //    formData.append('fileKey', fileToUpload, fileToUpload.name);
    //    return this.http.post(endpoint, formData, { headers: yourHeadersConfig })
    //      .map(() => { return true; })
    //      .catch((e) => this.handleError(e));
    //}

    postFile(
        fileToUpload: File,
        currentLang:string,
        sdcTitle:string,
        dctId:string,
        cshId : string): Observable<boolean>
    {
        const formData: FormData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append('dctId', dctId);
        formData.append('fileName',fileToUpload.name);
        formData.append('lanCode', currentLang);
        formData.append('sdcTitle', sdcTitle);
        formData.append('cshId', cshId);

        return this.http.post<any>('api/Rh/UploadSchemeDocument', formData);
    }
}
