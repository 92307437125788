import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class RHGuardService implements CanLoad {
    constructor(private authService: AuthService, private router: Router) {
        console.log("##RH-scv## RhGuard constructor");
    }
    //Call the authService to get the user's role and check if the user is a RH
    canLoad(route: Route): Promise<boolean> {
        console.log("##RH-scv## rh.GUARD canload triggered");
        let url: string = route.path;
        // We first need to set the role in the constructor injected authService beforte we can get the role
        // return Promise on the authsvc getRole function before isUserAffiliate eval
        return this.authService.getAsyncRole().then(value => {
            if (this.authService.isUserRH()) {
                console.log("##R-scv## user is rh");
                return true;
            } else {
                console.log("##R-scv## user is not rh");
                this.router.navigate(['/public']);
                return false;
            }
        }).catch((err) => {
            this.router.navigate(['/public']);
            return false;
        });
    }
} 
