
import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LancodeResultModel } from './models/lancode-result-model';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from './core/middleware-call/shared.service';

import { HeaderSharingService } from './core/services/header-sharing.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'app';
    seed: number; // for toggeling display of components
    headerBarHeight: number;
    collapedSideBar: boolean;

    // translations
    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        translate: TranslateService,
        private headerSharingService: HeaderSharingService,
        private sharedService: SharedService
    ) {

        // Subscribing to lang changes
        translate.addLangs(['en', 'de', 'fr']);
        this.sharedService.getLang().subscribe(
            result => {
                //translate.setDefaultLang('fr');
                if (result.lanCode == "fr" || result.lanCode == "de" || result.lanCode == "en") {
                    console.log("translate.use(result.lanCode);");
                    translate.use(result.lanCode); //try to set the browser language if that is available.
                }
                else {
                    //translate.use('fr'); //try to set the browser language if that is available.
                    console.log("this.cookieService.get('Language')"); // By default get the Language cookie value.
                    console.log(this.cookieService.get('Language'));
                    this.cookieService.get('Language'); // By default get the Language cookie value.
                }
            },
            error => {
                console.log("Error in app component for language translate")
                const browserLang = translate.getBrowserLang();
                // If there already is a cookie in the browser, we use it to set the lnguage
                if (this.cookieService.check('Language')) {
                    console.log("enter error if");
                    translate.use(this.cookieService.get('Language'));
                }
                else {
                    // If there is no cookie stored in the browser, we set french as default language until th user logs in or switch language
                    console.log("enter error else");
                    translate.setDefaultLang('fr');
                    translate.use(browserLang.match(/en|fr|de/) ? browserLang : 'fr');
                }
            }
        );


        // Subscribing to seed behavior object
        this.headerSharingService.seed.subscribe(value => {
            this.seed = value;
            console.log("setting seed value from headerservice : " + this.seed);
        })

        // subscribing global app to headerbar heigh detector
        //this.headerSharingService.getHeaderBarHeight().subscribe(headerBarHeight => {
        //    console.log("setting  headerBarHeight: " + headerBarHeight);
        //  this.headerBarHeight = headerBarHeight;
        //});

        //this.headerBarHeight = this.headerSharingService.getHeaderBarHeightValue();

    }

    // detect mobile : src:https://itnext.io/angular-code-design-for-responsive-websites-acd4259a478c
    ngOnInit(){
        console.log("ngOnInit app component");


        this.headerSharingService.getMobileStatus().subscribe( isMobile =>{
          if(isMobile){
            console.log('Mobile device detected + But nothing is happening here?')
          }
          else{
            console.log('Desktop detected + But nothing is happening here?')
          }
        });
        this.onResize();

        //this.headerSharingService.headerBarHeight.subscribe(value => {
        //    console.log("setting headerBarHeight value from headerservice : " + value);
        //    this.headerBarHeight = value;
        //});
        this.headerSharingService.getHeaderBarHeightAsObservable().subscribe(value => {
            //console.log("setting headerBarHeight value from headerservice : " + value);
            // this is magic. Mess with it and you get Errors in console when number of lines in headerbar change
            setTimeout(()=> {
                 this.headerBarHeight = value;
            }, 0);
        });
        //this.delayHeaderBarHeightSubscription();
    }

    //ngAfterViewInit(){
    //    console.log("ngAfterViewInit app component");
    //    //this.headerSharingService.headerBarHeight.subscribe(value => {
    //    //    console.log("setting headerBarHeight value from headerservice : " + value);
    //    //    this.headerBarHeight = value;
    //    //});
    //    this.delayHeaderBarHeightSubscription();
    //}

    //ngAfterViewChecked(){
    //    // this is its own lifecycle so no subscribe needed to prevent 
    //    console.log(">>> ngAfterViewChecked: getting headerBarHeight; before "+this.headerBarHeight);
    //    this.headerBarHeight = this.headerSharingService.getHeaderBarHeightValue();
    //    console.log(">>> ngAfterViewChecked: getting headerBarHeight; after "+this.headerBarHeight);
    //}

//TODO JKA : Put into timeout, because it must first be initialized, then wait for all to be generated inside router-outlet, then register to it... (hypothese nr 12)
        //this.headerSharingService.headerBarHeight.subscribe(value => {
        //    console.log("setting  headerBarHeight: " + value);
        //  this.headerBarHeight = value;
        //});
    //delayHeaderBarHeightSubscription(){
    //    setTimeout(() => {
    //        this.headerSharingService.headerBarHeight.subscribe(value => {
    //            console.log("setting  headerBarHeight: " + value);
    //          this.headerBarHeight = value;
    //        });
    //    }, 5000)
    //}


    onResize(){
        this.headerSharingService.checkWidth();
    }

    receiveCollapsed($event) {
        this.collapedSideBar = $event;
    }

}
