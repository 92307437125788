
import { Component, OnInit, HostListener, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

// Multi tab store sync
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AffService } from '../../core/middleware-call/aff.service';
import { PortfolioService } from '../../core/middleware-call/portfolio.service';
import { SharedService } from '../../core/middleware-call/shared.service';
import { RoleResultModel } from '../../models/role-result-model';
import { HeaderSharingService } from '../../core/services/header-sharing.service'; 
import { routerTransition } from '../../router.animations';

import { ErrorHandlerService } from '../../error-handler.service';


@Component({
    selector: 'app-plan-view',
    templateUrl: './plan-view.component.html',
    styleUrls: ['./plan-view.component.scss'],
    animations: [routerTransition()]
})

export class PlanViewComponent implements OnInit {

    /////////////////////////////////////////////
    /// Declarations
    /////////////////////////////////////////////

    currentTabsAffId: string; // Last AffId known by this Tab instance - set with middleware affid when page is first opened
    isPortfolioVisible: boolean;
    role: RoleResultModel = new RoleResultModel;

    seed: number;
    public isMobile: Boolean;
//moving to app.component
    //public headerBarHeight: number;


    /////////////////////////////////////////////
    /// Constructor injections & onInit       ///
    /////////////////////////////////////////////

    constructor(
        private http: HttpClient,
        private affService: AffService,
        private sharedService: SharedService,
        private portfolioService: PortfolioService,
        private headerSharingService: HeaderSharingService,
        public errorHandler: ErrorHandlerService,
        private route: ActivatedRoute) {
            console.log("plan view constructor");
        this.sharedService.getRole().subscribe(result => {
            this.role = result;
            console.log("result of getRole in plan-view : " + this.role);

            // Init Rh navigation to update it in the header bar 
            if (this.role.role == "RH") {
                this.headerSharingService.initRHNavigation();
            }
        });
        this.getHeaderSharingServiceSeed();
        this.headerSharingService.viewIsBusy.next(false); // oninit we reset the busy to prevent potential lockin

//moving to app.component
        //this.headerSharingService.getHeaderBarHeight().subscribe(headerBarHeight => {
        //    console.log("setting  headerBarHeight: " + headerBarHeight);
        //  this.headerBarHeight = headerBarHeight;
        //});
    }



    ngOnInit() {

        console.log("getPortfolioVisible");
        this.getPortfolioVisible().then(res => {
            console.log("Is this portfolio visible ? " + this.isPortfolioVisible); // Get the isVisible value from the MW. This value is used to show or not the Portfolio part.
            this.initStoreData(); // Ths takes the affId from mid and stores it into LocalStorage and Local variable
        });
        console.log("plan-view ngOnInit");
        //console.log("Is this portfolio visible ? " + this.isPortfolioVisible);

        this.onResize();
        this.headerSharingService.checkWidth();


        console.log('AFFID: '+sessionStorage.getItem('STORAGE_AFFID'));
    }


        // Might be DEPRECATED?
    @HostListener('mouseenter') onMouseEnter() {
        this.checkIfAffIdIsDirty();
    }

        // Might be DEPRECATED?
    @HostListener('window:focus', ['$event'])
    onFocus(event: any): void {
        this.checkIfAffIdIsDirty();
    }


    /////////////////////////////////////////////
    /// Class Methods
    /////////////////////////////////////////////


    /**
    * If this intance AffId is not the stored AffId then reload the page (Another tab is viewing another plan, so renew this page with that plans data)
    * */
    // DEPRECATED - Tabs are now standalone
    checkIfAffIdIsDirty() {
        let deprecated = true;
        if (deprecated){
            console.log("checkIfAffIdIsDirty is deactivated - tabs are standalone now");
        } else {
            // if current tab id == storeID, then all is good
            if (this.currentTabsAffId == null) {
            }
            else if (this.currentTabsAffId == localStorage.getItem("STORAGE_AFFID")) {
            } else {
                window.location.reload();
            }
        }

    }

    // Ths takes the affId from mid and stores it into LocalStorage (Common to all tabs) and Local variable
    initStoreData() {
        this.affService.getCurrentAffiliateSchemeID().subscribe(
            result => {
                console.log("X) result affId from mid session: " + result);
                this.currentTabsAffId = result;
                // should we even set this here? yes because new tab can override old ones?!
                localStorage.setItem("STORAGE_AFFID", this.currentTabsAffId); // this ... does this change like sesssion id?
            },
            error => {
                console.error("X) error - THIS SHOULD RELOAD PAGE TO LOGIN");
                this.currentTabsAffId = "0"; // not logged in or session is corrupt
                localStorage.clear();
                // TODO : Go to login page and prompt mid to destroy session etc?
                this.errorHandler.handleError(error);
            });
    }

    getSessionAffId() {
        this.affService.getCurrentAffiliateSchemeID().subscribe(
            result => {
                console.log("z) result affId from mid session: " + result);
                return result;
            },
            error => {
                return 0;
            });

    }

    getPortfolioVisible() {
        return new Promise((resolve, reject) => {
            console.log("getPortfolioVisible");
            this.portfolioService.isPortfolioVisible().subscribe(
                result => {
                    console.log("z) is Portfolio visible : " + result);
                    this.isPortfolioVisible = result;
                    resolve('OK');
                },
                error => {
                    console.error(error);
                    resolve('NOK');
                });

        })

  }

  // such subscriptions execute on every change of the headerservices value
  getHeaderSharingServiceSeed() {
      console.log("getHeaderSharingServiceSeed");
      this.headerSharingService.seed.subscribe(value => {
          this.seed = value;
          console.log("We check seed value from headerservice : " + this.seed);
      })
  }

  // for mobile : TODO : this should probably be done once during init... this seems memory hoggy....
  onResize() {
    this.headerSharingService.getMobileStatus().subscribe(isMobile => {
      this.isMobile = isMobile;
    });
  }

}
