import { Component, OnInit,HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UserInformationsChecksService } from '../services/user-informations-checks.service';
import { ChangePasswordModel } from '../../models/change-password-model';
import { ErrorHandlerService } from '../../error-handler.service';
import { SharedService } from '../../core/middleware-call/shared.service';
import { ToastrService } from 'ngx-toastr';
	import { TranslateService } from '@ngx-translate/core';

import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { ComponentCanDeactivate } from '../../core/services/pending-changes-guard';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

     @HostListener('window:beforeunload')
        canDeactivate(): Observable<boolean> | boolean {
        return this.canNavigate;
    }
    /*
     *  ChangePasswors component = change password page
     *
     *  Reached  by the user-information-check.service automatically or by the user from his settings
     *  
     */

    changePasswordModel: ChangePasswordModel;
    public canNavigate: boolean = true;

    public newPwAndConfirmPwAreDifferent: boolean = false
    public oldPwEqualNewPw: boolean = false;
    public regexNotRespected: boolean = false;
    public fieldsMissing: boolean = false;
    hasErrorOccured: boolean = false;// This is shit: shit name; wrong label (procs rule for pw composition label on generic error? WTF)
;
    role: string;

    httpErrorResponse: HttpErrorResponse;

    passwordForm = this.formBuilder.group({
        oldPw: [''],
        newPw: [''],
        confirmPw: ['']
    })



    constructor(
        private formBuilder: FormBuilder,
        private http: HttpClient,
        public errorHandler: ErrorHandlerService,
        private headerSharingService: HeaderSharingService,
        private sharedService: SharedService,
        public translate: TranslateService,
        private toastr: ToastrService,
        private checkServices: UserInformationsChecksService) { 
            console.log("constructor executed");
            this.headerSharingService.role.subscribe(result => {
                this.role = result;
            });
       }

    ngOnInit() {
        console.log("ngOnInit executed");
        //this.initPwForm();
    }

    // checks passwords form before sending it to MW
    changePassword() {
        console.log("changePassword log");
        // first clean up the former errors! why was this not done?
        this.hasErrorOccured = false; // This is shit: shit name; wrong label (procs rule for pw composition label on generic error? WTF)
        this.newPwAndConfirmPwAreDifferent = false;
        this.oldPwEqualNewPw = false;
        this.regexNotRespected = false;
        this.fieldsMissing = false;


        // invalidate check 1: are old and new pw equal
        if (this.passwordForm.get("oldPw").value == this.passwordForm.get("newPw").value) this.oldPwEqualNewPw = true;

        // invalidate check 2: are new pw and confirm pw different
        if (this.passwordForm.get("newPw").value != this.passwordForm.get("confirmPw").value)  this.newPwAndConfirmPwAreDifferent = true;

        // invalidate check 3 : is regex not respected
        const regex = new RegExp('((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*?[&@#(){}+=?!;:]).{8,})')
        if (this.passwordForm.get("newPw").value.match(regex) && this.passwordForm.get("confirmPw").value.match(regex)){
            console.log('both passwords match the regex');
        } else {
            this.regexNotRespected = true;
            console.log('one or both passwords dont match the regex');
        }
        
        // evaluation of the previous checks
        if (!this.oldPwEqualNewPw && !this.newPwAndConfirmPwAreDifferent && !this.regexNotRespected){
            // all invalidating conditions are false, so its all good for save
            this.changePasswordModel = new ChangePasswordModel();
            this.changePasswordModel.oldPassword = this.passwordForm.get("oldPw").value;
            this.changePasswordModel.newPassword = this.passwordForm.get("newPw").value;
            this.changePasswordModel.confirmPassword = this.passwordForm.get("confirmPw").value;

            this.sharedService.changePassword(this.changePasswordModel).subscribe(
                result => {
                    this.canNavigate = true;
                    this.checkServices.accountValidityCheck();
                },
                error => {
                    this.toastr.error(this.translate.instant('TS_Password_Changed_Fail_Toast'), '');
                    this.errorHandler.handleError(error); // this should go to error page, so why does hasErrorOccured even exist??? leving it in for testing of error page for now...
                    this.hasErrorOccured = true;
                });
        }

    }

}
