import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AffService } from '../../core/middleware-call/aff.service';

@Injectable()
export class UserConditionsFineService {

    /*
    * Service to check if all the User Conditions are validate
    * (conditions are : email needs to be entered, password needs to be changed, first fatca isn't validated yet or user conditions haven't been accepeted yet)
    *  
    */

    userConditionsAreFine: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(private http: HttpClient, 
        //private router: Router, 
        //public errorHandler: ErrorHandlerService,
private affService: AffService) {
            console.log("UserConditionsFineService constructor triggered");

        this.isFine();
    }

    // @Max Why is this a promise and what does it do?
        // Is it just to update affService userConditions variable?
        // If so, name it updateAffiliateUserConditionsCheck or something like that!
    isFine() {
            console.log("UserConditionsFineService isFine() triggered");
        return new Promise((resolve, reject) => {
            console.log("checking if user conditions are fine");
            this.affService.userConditionsAreFine().subscribe(
                result => {
                    this.userConditionsAreFine.next(result);
                    console.log(result);

                    if (result == true) {
                        console.log("UserConditionsFineService 1");
                        resolve('OK'); // why is this pretending to be a promise?
                    }
                    else {
                        console.log("UserConditionsFineService 0");
                        resolve("NOK"); // why is this pretending to be a promise?
                    }
                },
                error => {
                    console.log("error"); // this executes on session timeout where refresh results in a blank page
                    console.log(error);



                    //this.errorHandler.handleError(error);
                    resolve("NOK"); // why is this pretending to be a promise?
                });
        })

    }
}
