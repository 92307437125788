
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'; 
import { ErrorHandlerService } from '../../error-handler.service';
import { LoginService } from '../../core/middleware-call/login.service';
import { AffService } from '../../core/middleware-call/aff.service';
import { SharedService } from '../../core/middleware-call/shared.service';
import { RHService } from '../../core/middleware-call/rh.service';
import { saveAs } from 'file-saver/src/FileSaver';
import { AvailableExercicesModel } from '../../models/available-exercices-model';
import { AffiliateDocModel } from '../../models/affiliate-doc-model';
import { ProfileDataModel } from '../../models/profile-data-model';
import { DatePipe } from '@angular/common';
import { CountriesModel } from '../../models/countries-model';
import { ToastrService } from 'ngx-toastr';
import { DocTypeModel } from '../../models/doc-type-model';

// Guarantees.Component = SECTION 1A - Plan View

@Component({
    selector: 'app-various',
    templateUrl: './various.component.html',
    styleUrls: ['./various.component.scss']
})

export class VariousComponent implements OnInit {

    ////////////////
    //// DECLARATIONS
    //////////////////

    availableExercises: AvailableExercicesModel[]; // print certif content
    affDox: AffiliateDocModel[]; // view documents content
    docTypes: DocTypeModel[]; // Used to store pension's doc types
    numberOfDocs: number[];
    currentlyOpenedItemIndex: -1; // Used fo accordion stuff in template
    userProfileInfos: ProfileDataModel;
    public pipe = new DatePipe('fr');
    country: CountriesModel = new CountriesModel;

    role: string='';

    constructor(
        private modalService: NgbModal,
        private translate: TranslateService,
        public loginService: LoginService,
        private affService: AffService,
        private sharedService: SharedService,
        private rhService: RHService,
        public router: Router,
        public errorHandler: ErrorHandlerService,
        @Inject('BASE_URL') baseUrl: string,
        private toastr: ToastrService) {

    }

    ngOnInit() {
        this.sharedService.getRole().subscribe(result => {
            this.role = result.role;
        });
    }

    //---------------------\\
    //  Print certif part  \\
    //---------------------\\

    /**
     * MW call to get exercices to display in the modal
     */
    getICAvailableExercices() {
        return new Promise((resolve, reject) => {
            this.sharedService.getICAvailableExercices().subscribe(result => {
                console.log("getICAvailableExercices");
                console.log(result);
                this.availableExercises = result;
                resolve('OK');

            }, error => {
                console.error(error);
                console.error(error.status);
                if (error.status == 500) {
                    console.error("[3]  GetAffiliateDocuments handle 500");
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });
        });
    }


    // Function called by a button to open a modal with data in it
    openAvailableExercices(content) {
        // make the data call
        this.getICAvailableExercices().then(res => {
            console.log("[availableExercises] then part ,res = " + res);
            // then open the modal
            this.openVerticallyCentered(content);
        });
    }


    // Function called when clicking on an exercice in the modal to download a pdf
    getExerciseContent(exeId) {
        this.toastr.success('', this.translate.instant('TS_Document_Generation_Started_Toast'));
        console.log("getExerciseContent with exeId:" + exeId);
        this.modalService.dismissAll();
        this.sharedService.getExoFromStream(exeId).subscribe((data) => {
            saveAs(data, this.translate.instant('TS_Certificat') + "_" +exeId + "_" + sessionStorage.getItem("STORAGE_AFFID")+'.pdf');
        }, error => {
            console.error(error);
            this.toastr.warning('', this.translate.instant('TS_Document_Generation_Failed_Toast'));
        });
    }

    //------------------------\\
    // View pension docs part \\
    //------------------------\\

    // Function called by the button to open the modal with data in it
    openPensionDocsModal(content) {

        this.getDocumentTypes().then(result => {
            this.getAffiliateDocuments().then(res => {
                console.log("[getAffiliateDocuments] then part ,res = " + res);
                this.openVerticallyCentered(content);
                this.determinDocsPerDctId(this.affDox);
            })
        });
    }

    getDocumentTypes() {
        return new Promise((resolve, reject) => {
            this.sharedService.getDocumentTypes().subscribe(
                result => {
                    console.log("result of getDocumentTypes :");
                    console.log(result);
                    this.docTypes = result;
                    resolve('OK');
                },
                error => {
                    console.error("error on callback getDocumentTypes");
                    console.error(error);
                    this.errorHandler.handleError(error);
                    //resolve('NOK');
                });
        });
    }


    // MW call to get documents to display in the modal
    getAffiliateDocuments() {
        return new Promise((resolve, reject) => {
            this.sharedService.getAffiliateDocuments().subscribe(result => {
                console.log(result);

                this.affDox = result;
                //this.numberOfDocs = [0, 0, 0, 0, 0, 0, 0, 0]; // old hardcoded bad version

                this.numberOfDocs = new Array(this.docTypes.length); // collection counter for doc indexer
                for (let a = 0; a<this.numberOfDocs.length ; a++){
                    this.numberOfDocs[a] = 0; // ini all at zero
                }

                resolve('OK');

            }, error => {
                console.error(error);
                console.error(error.status);
                if (error.status == 500) {
                    console.error("[3]  GetAffiliateDocuments handle 500");
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });
        });

    }


    // Function called when clicking on a doc in the modal to download it as a pdf
    getDocumentContent(bobId, fileName) {
        console.log("getDocumentContent with bobid:" + bobId + " and filename : " + fileName);
        this.toastr.success('', this.translate.instant('TS_Document_Generation_Started_Toast'));
        this.modalService.dismissAll();
        this.sharedService.getFileFromStream(bobId).subscribe((data) => {
            saveAs(data, fileName + '');
        }, error => {
            console.error(error);
            this.toastr.warning('', this.translate.instant('TS_Document_Generation_Failed_Toast'));
        });
    }

    determinDocsPerDctId(result) {
        for (let i = 0; i < result.length; i++) {
            for(let t = 0 ; t < this.docTypes.length ; t++){
                if (result[i].dctId == this.docTypes[t].dctId){
                    //console.log("Found doc n#"+i+" with dctId "+allAffDox[i].dctId+" to be equal to type "+t+" ("+t+":"+this.docTypes[t].dctId+") incrementing space t on numberOfDocs from "+this.numberOfDocs[t]);
                    this.numberOfDocs[t] = this.numberOfDocs[t] + 1; // incrementing the same spot as this doc types iteration value 't', as both arrays should reflect the dctId value, independantly of gaps etc
                }
            }
        }
    }


    //--------------------\\
    // Profile infos part \\
    //--------------------\\

    openUserInfos(content) {
        this.getAffiliateInformation().then(result => {
            this.getCountry();
            console.log("[getAffiliateInformation] then part ,res = " + result);
            this.openVerticallyCentered(content);
        });
    }


    getCountry() {
        return new Promise((resolve, reject) => {

            this.sharedService.getCountries().subscribe(
                result => {
                    this.country.couCode = "";
                    for (let i: number = 0; i < result.length; i++) {
                        if (this.userProfileInfos.couCode == result[i].couCode)
                            this.country = result[i];
                    }
                    resolve('OK')
                },
                error => {
                    console.error("error on callback GetCountries");
                    console.error(error);
                    this.errorHandler.handleError(error);
                    resolve('NOK');
                });
        });
    }


    getAffiliateInformation() {
        return new Promise((resolve, reject) => {
            this.rhService.getAffiliateInformation(Number(localStorage.getItem("STORAGE_AFFID"))).subscribe(
                result => {
                    console.log("result of getAffiliateInformation :");
                    console.log(result);
                    this.userProfileInfos = result;
                    resolve('OK');
                },
                error => {
                    console.error("error on callback getAffiliateInformation");
                    console.error(error);
                    this.errorHandler.handleError(error);
                    resolve('NOK');
                });
        });
    }



    //--------------------\\
    // Profile infos part \\
    //--------------------\\
    //generateAccountStatement() {
    //    this.toastr.success('', this.translate.instant('TS_Document_Generation_Started_Toast'));
    //    this.rhService.generateAccountStatement(Number(localStorage.getItem("STORAGE_AFFID"))).subscribe(
    //        result => {
    //            saveAs(result, "AccountStatement_" + localStorage.getItem("STORAGE_AFFID") + '.pdf');
    //            console.log(result);
    //        },
    //        error => {
    //            this.toastr.warning('', this.translate.instant('TS_Document_Generation_Failed_Toast'));
    //            console.error("error on callback GenerateAccountStatement");
    //            console.error(error);
    //            //this.toastr.warning('', this.translate.instant('TS_Document_Generation_Fail_Toast'));
    //        }
    //    );
    //}

    triggerAccountStatement() {
console.log("id:"+sessionStorage.getItem("STORAGE_AFFID"));
        this.toastr.success('',this.translate.instant('TS_Document_Generation_Started_Toast'));
        this.affService.generateAccountStatement(Number(sessionStorage.getItem("STORAGE_AFFID"))).subscribe(
            result => {
                saveAs(result,this.translate.instant('TS_AccountStatement')+"_"+sessionStorage.getItem("STORAGE_AFFID")+'.pdf');
                console.log(result);
            },
            error => {
                this.toastr.warning('',this.translate.instant('TS_Document_Generation_Failed_Toast'));
                console.error("error on callback GenerateAccountStatement");
                console.error(error);
            }
        );
    }


    //------------\\
    // Popup part \\
    //------------\\

    openVerticallyCentered(content) {
        this.closeModal().then(res => {
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true, keyboard: true });
        });
    }

    closeModal() {
        return new Promise((resolve, reject) => {
            this.modalService.dismissAll();
            resolve('OK');
        });
    }


    // Functions regarding accordion in template 
    setOpened(itemIndex) {
        this.currentlyOpenedItemIndex = itemIndex;
    }

    setClosed(itemIndex) {
        if (this.currentlyOpenedItemIndex === itemIndex) {
            this.currentlyOpenedItemIndex = -1;
        }
    }
}
