import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserConditionsFineService } from './user-conditions-fine.service';
import { UserInformationsChecksService } from '../../shared/services/user-informations-checks.service'
//import { ErrorHandlerService } from '../../error-handler.service';


@Injectable({
    providedIn: 'root',
})
export class UserConditionsGuardService implements CanActivate {

    areConditionsFine: boolean = false;
    constructor(
        //public errorHandler: ErrorHandlerService,
        //public router: Router,
        private userConditionsFineService: UserConditionsFineService,
        private checkServices: UserInformationsChecksService) {
        console.log("UserConditionsGuardService constructor");

        this.userConditionsFineService.userConditionsAreFine.subscribe(
        result => {
            this.areConditionsFine = result;
            console.log("this.areConditionsFine");
            console.log(result);

                // canDeactivate in routing provokes blank page on refresh when session has expired - this prevents this via exceptions
                /*
                  if (this.router.url.indexOf('fatca') > -1 && result == null){
                                    this.errorHandler.handleError("default");
                                }

                        },
                        error => {
                            console.log("this.areConditionsFine error");
                            console.log(error);
                            this.errorHandler.handleError(error);
                */
        });
    }
    //Call the UserConditionsFineService to check if the user has checks required
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve) => {
            this.userConditionsFineService.isFine().then(result => {
                if (this.areConditionsFine == true) {
                    console.log(" UCFService.isFine() true");
                    resolve(true);
                    return true;
                } else {
                    this.checkServices.accountValidityCheck();
                    console.log(" UCFService.isFine() false");
                    resolve(false);
                    return false;
                }
            });

        })
    }

} 
