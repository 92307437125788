import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class AffiliateGuardService implements CanLoad {
    constructor(private authService: AuthService, private router: Router) {
        console.log("##A-scv## AffGuard constructor");
    }
    // Call the authService to get the user's role and check if the user is an affiliate
    // Return type must be Promise because the GetRole wil set the var InfinityMiddleware session and resolving the promise will get and eval it to be aff
    canLoad(route: Route): Promise<boolean> {
        console.log("##A-scv## affiliate.GUARD canload triggered");
        let url: string = route.path;
        // We first need to set the role in the constructor injected authService beforte we can get the role
        // return Promise on the authsvc getRole function before isUserAffiliate eval
        return this.authService.getAsyncRole().then(value => {
            if (this.authService.isUserAffiliate()) {
                console.log("##A-scv## user is affiliate");
                return true;
            } else {
                console.log("##A-scv## user is not aff");
                this.router.navigate(['/public']);
                return false;
            }
        }).catch((err) => {
            this.router.navigate(['/public']);
            return false;
        });

    }
}

