import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginService } from "./core/middleware-call/login.service";
import { HeaderSharingService } from "./core/services/header-sharing.service";

@Injectable()
export class ErrorHandlerService {

    constructor(
        private router: Router, 
        private loginService: LoginService,
        private headerSharingService: HeaderSharingService,
        private modalService: NgbModal) { }
    

    handleError(httpErrorResponse) {

        // close all potentially still open modals - tobe tested - very maxified
        this.modalService.dismissAll();

        this.loginService.isTokenValid().subscribe(
          result => {
            console.log(result);
            if (result == true) {

                console.log(httpErrorResponse.value);
                // SESSION IS STILL VALID - reroute depending on code
                if (httpErrorResponse.status == 400)
                    this.router.navigate(['/public/badRequest']);
                else if(httpErrorResponse.status==401) {
                    this.router.navigate(['/public/unauthorized']);
                } else if (httpErrorResponse.status == 403) {
                    this.router.navigate(['/public/forbidden']);
                }
                else if (httpErrorResponse.status == 404)
                    this.router.navigate(['/public/notFound']);
                else if (httpErrorResponse.status == 500)
                    this.router.navigate(['/public/internalServerError']);
                else
                    this.router.navigate(['/public']);

            }
            else {

                // SESSION IS EXPIRED - killing session stuff and logging out, then forwarding to session expired page

                    console.log("token invalid - handle as session expired");
                  //this.headerSharingService.role.next(null); // already done during logoff, why do it twice?
                  //this.headerSharingService.isUserLoggedIn.next(false); // already done during logoff, why do it twice?
                  this.headerSharingService.logOff().then(res => {
                    console.log("goto /public/sessionExpired - router.routerState");
                    console.log(this.router.routerState);
            //this.router.navigate(['/public']);
//this.router.urlUpdateStrategy = "eager"; // does nothing except expired page does not redirect to login on refresh it seems
//this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                    this.router.navigate(['/public/sessionExpired']); // this gets ignored when coming from fatca wizard (/affiliate/fatca)
                    //this.router.navigateByUrl('/public/sessionExpired'); // https://stackoverflow.com/questions/45025334/how-to-use-router-navigatebyurl-and-router-navigate-in-angular
                    console.log(this.router.routerState);
                  }); 
                    //this.router.navigate(['/public/sessionExpired']); // this gets ignored when coming from fatca wizard (/affiliate/fatca)
            };
          },
          error => {
            console.log(error);
            if (error.ok == true)
              console.log("############### Token still Valid"); // stay on error page because something else went wrong (prompt admin message)
            else //if (error.ok == "false")
            {
                  // SESSION IS EXPIRED + ERROR - killing session stuff and logging out, then forwarding to session expired page
                  this.headerSharingService.role.next(null);
                  this.headerSharingService.isUserLoggedIn.next(false);
                  this.headerSharingService.logOff().then(res => {
                    this.router.navigate(['/public/sessionExpired']);
                  });
            }
          }
        )
    }
}
