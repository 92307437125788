import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChartLineModel } from '../../models/chart-line-model';
import { FundYieldModel } from '../../models/fund-yield-model';
import { BenefitsResultModel } from '../../models/benefits-result-model';
import { RiskActurialDatasResultModel } from '../../models/risk-acturial-datas-result-model';
import { RetirementActurialDatasResultModel } from '../../models/retirement-acturial-datas-result-model';
import { OperationModel } from '../../models/operation-model';
import { PeriodModel } from '../../models/period-model';

@Injectable()
export class HomeService {

    constructor(private http: HttpClient) {

    }

    getBenefits() {
        return this.http.get<BenefitsResultModel[]>('api/Home/GetBenefits'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    getBenefitsForAffid(affId) {
        return this.http.get<BenefitsResultModel[]>('api/Home/GetBenefits'+ "?affId=" + affId);
    }

    hasExoneration () {
        return this.http.get<boolean>('api/Home/HasExoneration'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getRetirementActuarialDatas() {
        return this.http.get<RetirementActurialDatasResultModel[]>('api/Home/GetRetirementActuarialDatas'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getFundValorisations(fndId: number) {
        return this.http.get<ChartLineModel[]>('api/Home/GetFundValorisations?fndId=' + fndId + "&affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getFundYields(fndId: number) {
        return this.http.get<FundYieldModel[]>('api/Home/GetFundYields?fndId=' + fndId + "&affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getRiskActuarialDatas(benType: string) {
        return this.http.get<RiskActurialDatasResultModel[]>('api/Home/GetRiskActuarialDatas' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID") + "&benType=" + benType);
    }

    getAvailableExercisePeriods() {
        return this.http.get<PeriodModel[]>('api/Home/GetAvailableExercisePeriods' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getOperations() {
        return this.http.get<OperationModel[]>('api/Home/GetOperations' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getOperationsByPeriod(exeId) {
        return this.http.get<OperationModel[]>('api/Home/GetOperationsByPeriod?exeId=' + exeId + "&affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

}
