import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RoleResultModel } from '../../models/role-result-model';
//import { Router } from '@angular/router';
@Injectable()
export class HeaderSharingService{

    // These basicly broadcast once somebody used this.headerSharingService.isUserLoggedIn.subscribe on them, allowing live update of value in template without refresh
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public role: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public seed: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    //public headerBarHeight: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    public headerBarHeight = new Subject();
    private isMobile = new Subject(); // src:https://itnext.io/angular-code-design-for-responsive-websites-acd4259a478c
    public screenWidth: string; // param that determins if isMobile is true

    // These basicly brodcasts the company, scheme, anf affiliate names th RH is working on
    public currentCompanyName: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public currentSchemeName: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public currentAffiliateName: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public hasOnlyOneCompany: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public viewIsBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // should all components other then plan view reset this to false? Just to assure we cant get locked in?

    constructor(
        //private router: Router,
        private http: HttpClient) {
        console.log("INI isUserLoggedIn based on role return from mid.");
        this.initIsLogged();
        this.initIsRole();
        this.initIsSeed();
        this.checkWidth();
        this.initRHNavigation();
        //this.initHeaderBarHeight(); // set via header component once it is ready
    }

    initIsLogged() {
        this.http.get<boolean>('api/Shared/IsLoggedIn').subscribe(
            result => {
                this.isUserLoggedIn.next(result);
                console.log("isUserLoggedIn header in sharing service : " + result);
            },
            error => {
                console.error("error on callback GetRole");
                console.error(error);
            });
    }

    initIsRole() {
        this.http.get<RoleResultModel>('api/shared/GetRole').subscribe(
            result => {
                this.role.next(result.role);
                console.log(">>>> Dans le header sharing service, le role est : " + result.role);
            },
            error => {
                console.error("error on callback GetRole");
                console.error(error);
            })
    }

    inithasOnlyOneCompany(bool: boolean) {
        this.hasOnlyOneCompany.next(bool);
    }

    // Seed modifies how things are shown - allows to customize the enduser UI in browser
    initIsSeed(){
        this.seed.next(7);
    }

    // Seed modifies how things are shown - allows to customize the enduser UI in browser
    //initHeaderBarHeight(){
    //    this.headerBarHeight.next(1);
    //}

    getHeaderBarHeightAsObservable(): Observable<any> {
        return this.headerBarHeight.asObservable();
    }

    //getHeaderBarHeightValue(): any {
    //    return this.headerBarHeight.value;
    //}


    // * used in internal server error page if session token has been detected to be invalid
    logOff(): Promise<any> {
      return this.http.get('api/Shared/LogOff')
        .toPromise().then(res =>{
          console.log("LogOff result: "+res); // seems to be null always
          this.role.next(null);
          this.isUserLoggedIn.next(false);
          sessionStorage.clear();
                    //this.router.navigate(['/public']); // this gets ignored when coming from fatca wizard (/affiliate/fatca)

        });
    }


    initRHNavigation() {
        this.currentCompanyName.next(sessionStorage.getItem("RH_NAV_CIENAME"));
        this.currentSchemeName.next(sessionStorage.getItem("RH_NAV_SCHNAME"));
        this.currentAffiliateName.next(sessionStorage.getItem("RH_NAV_AFFNAME"));
    }

    // mobiile 
    onMobileChange(status: boolean) {
        this.isMobile.next(status);
    }

    getMobileStatus(): Observable<any> {
        return this.isMobile.asObservable();
    }

    checkWidth() {
        var width = window.innerWidth;
        if (width <= 768) {
            this.screenWidth = 'sm';
            this.onMobileChange(true);
        } else if (width > 768 && width <= 992) {
            this.screenWidth = 'md';
            this.onMobileChange(false);
        } else {
            this.screenWidth = 'lg';
            this.onMobileChange(false);
        }
    }
}
