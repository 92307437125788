import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../error-handler.service';
import { ValidityResultModel } from '../../models/validity-result-model';

@Injectable({
    providedIn: 'root'
})
export class UserInformationsChecksService {

    /*
    *  Service to check if a user (rh or affiliate) needs to validate conditions
    */

    constructor(private http: HttpClient,private router: Router,
        public errorHandler: ErrorHandlerService) { }

    // Redirect the User depending on if he needs to validate some condition
    public accountValidityCheck() {

        console.log("secondCheckIsOn");

        this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
            result => {
                console.log(result);
                console.log("USER ROLE: "+result.role);

                if(result.role == "AFF" && result.passwordNeedsToBeChanged==true) {
                    console.log("AVC step:1");
                    this.router.navigate(['/affiliate/changePassword']);
                } else if(result.role == "AFF" && result.emailNeedsToBeEntered==true) {
                    console.log("AVC step:2");
                    this.router.navigate(['/affiliate/registerEmail']);
                } else if(result.role == "AFF" && result.generalConditionsNeedToBeValidated==true) {
                    console.log("AVC step:3");
                    this.router.navigate(['/affiliate/generalConditions']);

                } else if(result.role=="RH" && result.passwordNeedsToBeChanged==true) {
                    console.log("AVC step:1");
                    this.router.navigate(['/rh/changePassword']);
                } else if(result.role=="RH" && result.emailNeedsToBeEntered==true) {
                    console.log("AVC step:2");
                    this.router.navigate(['/rh/registerEmail']);
                } else if(result.role=="RH" && result.generalConditionsNeedToBeValidated==true) {
                    console.log("AVC step:3");
                    this.router.navigate(['/rh/generalConditions']);

                } else if(result.role == "AFF" && result.fatcaMustBeDisplayed==true) {
                    console.log("AVC step:4");
                    this.router.navigate(['/affiliate/fatca']);
                } else if(result.role == "AFF") {
                    console.log("AVC step:6");
                    this.router.navigate(['/affiliate']);
                } else if(result.role=="RH") {
                    console.log("AVC step:7");
                    this.router.navigate(['rh/enterpriseSelect']);

                } else {
                    console.log("AVC step:8");
                    this.router.navigate(['/']);
                }


            },
            error => {
                console.error(error);
                this.errorHandler.handleError(error);
            }
        );
    }



    // Old max version (this does not respect UML specs)
    //public accountValidityCheck() {

    //		console.log("secondCheckIsOn");

    //		this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
    //				result => {
    //					console.log(result);
    //					console.log("#GUC# A1");
    //						// 
    //						if (result.passwordNeedsToBeChanged == true) {
    //								console.log("#GUC# B2 = password must be changed");
    //								this.router.navigate(['/affiliate/changePassword']);
    //						}



    //						else if (result.role == "AFF") {
    //								console.log("#GUC# B2 = user is an AFF  ");
    //								if (result.emailNeedsToBeEntered == true) {
    //									  console.log("#GUC# C3 = email is needed  ");
    //									  this.router.navigate(['/affiliate/registerEmail'])
    //								}
    //								else if (result.generalConditionsNeedToBeValidated == true) {
    //									  console.log("#GUC# C4 = must accept conditions  ");
    //									  this.router.navigate(['/affiliate/generalCondition']);
    //								}
    //								else if (result.fatcaMustBeDisplayed == true) {
    //									  console.log("#GUC# C5 = fatca needed  ");
    //									  this.router.navigate(['/affiliate/fatca']);
    //                                      }
    //                                      else if (result.riskProfileMustBeSet == true) {
    //                                          console.log("#GUC# C6 = risk profile needed  ");
    //                                          this.router.navigate(['/affiliate/riskProfile']);
    //                                      }
    //								else {
    //									  console.log("#GUC# C7 = all is good  ");
    //									  this.router.navigate(['/affiliate']);
    //								}

    //						}
    //						else if (result.role == "RH") {
    //								console.log("#GUC# B8 = rh ");
    //								this.router.navigate(['rh/enterpriseSelect']);
    //						}
    //						else {
    //								console.log("#GUC# B9 ");
    //								this.router.navigate(['/']);
    //						}
    //				},
    //				error =>
    //                              {
    //                                  console.error(error);
    //                                  this.errorHandler.handleError(error);
    //                              }
    //          );
    //}


    public canAccessFatca(): Promise<boolean> {

        return new Promise(resolve =>
            this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
                result => {
                    console.log(result);
                    if(result.passwordNeedsToBeChanged==true||result.emailNeedsToBeEntered==true||result.generalConditionsNeedToBeValidated==true) {
                        resolve(false);
                        return false;
                    }
                    else {
                        console.log(result.passwordNeedsToBeChanged);
                        console.log(result.emailNeedsToBeEntered);
                        console.log(result.generalConditionsNeedToBeValidated);
                        resolve(true);
                        return true;
                    }
                },
                error => {
                    console.error(error);
                    resolve(false);
                    return false;
                }));

    }

    public canAccessRegisterEmail(): boolean {
        console.log("canAccessRegisterEmail");
        this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
            result => {
                console.log(result);
                if(result.passwordNeedsToBeChanged==true) {
                    return false;
                }
                return true;
            },
            error => {
                console.error(error);
                this.errorHandler.handleError(error);
            });
        return false;
    }

    public canAccessGeneralConditions(): boolean {

        console.log("canAccessGeneralConditions");
        this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
            result => {
                console.log(result);
                if(result.passwordNeedsToBeChanged==true||result.emailNeedsToBeEntered==true) {
                    return false;
                }
                return true;
            },
            error => {
                console.error(error);
                this.errorHandler.handleError(error);
            });
        return false;
    }

    public canAccessRiskProfile(): Promise<boolean> {

        return new Promise(resolve =>
            this.http.get<ValidityResultModel>('api/Login/GetUserConditions').subscribe(
                result => {
                    console.log(result);
                    if(result.passwordNeedsToBeChanged==true||result.emailNeedsToBeEntered==true||result.generalConditionsNeedToBeValidated==true||result.fatcaMustBeDisplayed==true) {
                        resolve(false);
                        return false;
                    }
                    else {
                        console.log(result.passwordNeedsToBeChanged);
                        console.log(result.emailNeedsToBeEntered);
                        console.log(result.generalConditionsNeedToBeValidated);
                        console.log(result.fatcaMustBeDisplayed);
                        resolve(true);
                        return true;
                    }
                },
                error => {
                    console.error(error);
                    resolve(false);
                    return false;
                }));

    }

}
