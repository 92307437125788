
import { Component, OnInit, Inject, ViewEncapsulation  } from '@angular/core';
import { UserInformationsChecksService } from '../../shared/services/user-informations-checks.service';
import { AffService } from '../../core/middleware-call/aff.service';
import { routerTransition } from '../../router.animations';
import { TranslateService } from '@ngx-translate/core'; 
import { SharedService } from '../../core/middleware-call/shared.service';
//import { PurgeInnerHtmlPipe } from '../../custompipes/purge-innerhtml.pipe';

@Component({
    selector: 'app-general-conditions',
    templateUrl: './general-conditions-review.component.html',
    styleUrls: ['./general-conditions-review.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [routerTransition()]
})
export class GeneralConditionsReviewComponent implements OnInit {
    placeholder ="&nbsp;";
    isActive = false;
    companyName: string;

    constructor(
        private sharedService: SharedService,
        public translate: TranslateService,
        //public purgeInnerHtml: PurgeInnerHtmlPipe,
        @Inject('BASE_URL') baseUrl: string
    ) {

    }

    ngOnInit() {
        this.getCompanyName(); // this is the client company name as in hoster of the application company name (not the selected enterprise)

    }

    getCompanyName() {
        return new Promise((resolve,reject) => {
            console.log("getCompanyName triggered");
            this.sharedService.getCompanyName().subscribe(result => {
                this.companyName=result.companyName;
                resolve("OK")
            },error => {
                console.error("error on callback isPortfolioRepartitionEnabled");
                console.error(error);
                this.companyName="";
                resolve("NOK");
            })
        });
    }


}
