import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AffService } from '../middleware-call/aff.service';
import { PortfolioAccessibilityModel } from '../../models/portfolio-accessibility-model';
import { PortfolioService } from '../middleware-call/portfolio.service';
import { HeaderSharingService } from './header-sharing.service';
@Injectable()
export class SidebarSharingService {

    // These basicly broadcast once somebody used this.sidebarSharingService on them, allowing live update of value in template without refresh
    public affId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public hasChosenPlan: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public schName: BehaviorSubject<string> = new BehaviorSubject<string>(null);// <--- @Max: If this is only to display the plan name in title, it seems Overkill!
    public cieName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public portfolioAccessibility: BehaviorSubject<PortfolioAccessibilityModel> = new BehaviorSubject<PortfolioAccessibilityModel>(null);

    public portfolioTemplate: PortfolioAccessibilityModel = new PortfolioAccessibilityModel;


    constructor(
        private headerSharingService: HeaderSharingService,
        private http: HttpClient, private affService: AffService,
        private portfolioService: PortfolioService) {

            console.log("INI affId based on role return from mid");
            console.log("@@@@@@@@@@@@1@ headerSharingService.isUserLoggedIn "+headerSharingService.isUserLoggedIn);

            // prevent this if not logged in god dammit
            if(headerSharingService.isUserLoggedIn){

                this.initAffId().then(res => {
                    console.log("SUCCESS FOR INIT AFF ID");
                    this.setSchName(sessionStorage.getItem("STORAGE_SCHNAME"));
                    this.setCieName(sessionStorage.getItem("STORAGE_CIENAME"));
                    if (this.hasChosenPlan.value == true)
                        this.initPortfolioAccessibility();
                });
            }else{
                console.log("@@@@@@@@@@@@2@ headerSharingService.isUserLoggedIn "+headerSharingService.isUserLoggedIn);
            }

        }

    /**
     * Init the affiId with the value in SessionStorage and set hasChosenPlan accordingly
     */
    initAffId() {
        return new Promise((resolve, reject) => {

            this.affId.next(Number(sessionStorage.getItem("STORAGE_AFFID")));
            if (sessionStorage.getItem("STORAGE_AFFID") != "" && (sessionStorage.getItem("STORAGE_AFFID")) != null && (sessionStorage.getItem("STORAGE_AFFID")) != "0") {
                this.hasChosenPlan.next(true);
                console.log("hasChosenPlan " + this.hasChosenPlan.getValue());
                resolve('OK');
            }
            else {
                this.hasChosenPlan.next(false);
                console.log("hasChosenPlan " + this.hasChosenPlan.getValue());
                resolve('OK');
            }


            //this.http.get<string>('api/Aff/GetCurrentAffiliateSchemeID').subscribe(
            //    result => {
            //        console.log("************************************************");
            //        console.log(result);
            //        console.log("************************************************");
            //        this.affId.next(Number(sessionStorage.getItem("STORAGE_AFFID")));

            //        console.log("getCurrentAffiliateSchemeID sidebar in sharing service : " + result);
            //        resolve('OK');
            //    },
            //    error => {
            //        console.error("error on callback getCurrentAffId");
            //        console.error(error);
            //        resolve('NOK');
            //    });

        })
    }

    /**
     * Set a new value to affId, updates hasChosenPlan accordingly and set the affId in Session Storage
     * If the user has selected a plan, this function also calls initPortfolioAccessibility to get this plan's isreadOnly and isVisible values
     * @param value
     */
    setAffId(value: number) {
        this.affId.next(value);
        sessionStorage.setItem("STORAGE_AFFID", value.toString());
        if (this.affId.value > 0) {
            this.hasChosenPlan.next(true);
            this.initPortfolioAccessibility();
        }
        else {
            this.hasChosenPlan.next(false);
        }
    }

    resetAffId() {
        this.setAffId(0);
    }

    setHasChosenPlan(value: boolean) {
        this.hasChosenPlan.next(value)
    }

    // Set Scheme name to be displayed in header bar
    // TODO : Merge Header and Sidebar Services
    setSchName(schName: string) {
        console.log("[[[[[[[[[[[ Putting schName into SessionStorage through sidebar service :"+schName);
        sessionStorage.setItem("STORAGE_SCHNAME", schName);
        this.schName.next(schName); // <--- @Max: If this is only to display the plan name in title, it seems Overkill!
    }

    setCieName(cieName: string) {
        console.log("[[[[[[[[[[[ Putting CieName into SessionStorage through sidebar service :"+cieName);
        sessionStorage.setItem("STORAGE_CIENAME", cieName);
        this.cieName.next(cieName);
    }
    
    /**
     * Get the portfolio accessibility dependning on the affId in SessionStorage
     */
    initPortfolioAccessibility() {
        console.log("###################################################### #### ");
        console.log("initPortfolioAccessibility");
        console.log("initPortfolioAccessibility");
        return new Promise((resolve, reject) => {
            console.log("isPortfolioReadOnly");
            this.portfolioService.isPortfolioReadOnly().subscribe(
                result => {
                    this.portfolioTemplate.isReadOnly = result;
                });
            console.log("getPortfolioVisible");
            this.portfolioService.isPortfolioVisible().subscribe(
                result => {
                    this.portfolioTemplate.isVisible = result;
                });
            this.portfolioAccessibility.next(this.portfolioTemplate);
            resolve('OK');
            //this.portfolioService.isPortfolioRepartitionEnabled().subscribe(
            //    result => {
            //        this.portfolioTemplate.isRepartitionEnabled = result;
            //    });
            //this.portfolioService.isPortfolioSwitchEnabled().subscribe(
            //    result => {
            //        this.portfolioTemplate.isSwitchEnabled = result;
            //    });
        })


    }
}
