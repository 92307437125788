import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { InvestmentRepartitionPieDataModel } from '../../models/investment-repartition-pie-data-model';
import { RepartitionChartLineModel } from '../../models/repartition-chart-line-model';
import { PortfolioDetailsModel } from '../../models/portfolio-details-model';
import { InProcessSwitchModel } from '../../models/in-process-switch-model';
import { SwitchInvestmentProductModel } from '../../models/switch-investment-product-model';
import { InvestmentProductModel } from '../../models/investment-product-model';
import { InvestmentRepartitionProduct } from '../../models/investment-repartition-product';
import { TgDetailsModel } from '../../models/tg-details-model';


/* Service to connect to the backend methods under the portfolio service */

@Injectable()
export class PortfolioService {

    constructor(private http: HttpClient) {
        console.log("@@@@ NO WAY @@@ ");
    }


    ///    GET CALLZ    \\\

    getInvestmentRepartitionProductList() {
        return this.http.get<any>('api/Portfolio/GetInvestmentRepartitionProductList'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getNonNullInvestmentRepartitionProductArray() {
        return this.http.get<InvestmentRepartitionPieDataModel[]>('api/Portfolio/GetNonNullInvestmentRepartitionProductArray'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    // get all porfolios available to this user (back gathers all porfolio visible by perId extracted from mid's authToken)
    getCumulatedPortfolios() {
        return this.http.get<PortfolioDetailsModel[]>('api/Portfolio/GetCumulatedPortfolios');
    }
    // get all guaranteed rates of this users plans via GetCumulatedGuarantedProductRates
    getCumulatedGuarantedProductRates() {
        return this.http.get<TgDetailsModel[]>('api/Portfolio/GetCumulatedGuarantedProductRates');
    }

    //plan view constructor uses these to load the pie data for section 3's porfolio pie chart
    getPortfolioDetailsArray() {
        return this.http.get<PortfolioDetailsModel[]>('api/Portfolio/GetPortfolioDetailsArray'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    getNonNullPortfolioDetailsArray() {
        return this.http.get<PortfolioDetailsModel[]>('api/Portfolio/GetNonNullPortfolioDetailsArray'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    getNonNullPortfolioDetailsArrayForAffId(affId) {
        return this.http.get<PortfolioDetailsModel[]>('api/Portfolio/GetNonNullPortfolioDetailsArray'+ "?affId=" + affId);
    }
    // version for overview page where session id can not be used
    getNonNullPortfolioDetailsArrayWithSpecificAffId(affId : number) {
        return this.http.get<PortfolioDetailsModel[]>('api/Portfolio/GetNonNullPortfolioDetailsArray'+ "?affId=" + affId);
    }


    isPortfolioRepartitionEnabled() {
        return this.http.get<any>('api/Portfolio/IsPortfolioRepartitionEnabled'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    isPortfolioSwitchEnabled() {
        return this.http.get<any>('api/Portfolio/IsPortfolioSwitchEnabled'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }
    isPortfolioReadOnly() {
console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ XXX @@@@@");
        return this.http.get<boolean>('api/Portfolio/IsPortfolioReadOnly'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getSchemeCurCode() {
        return this.http.get('api/Portfolio/GetSchemeCurCode'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"), { responseType: 'text' });
    }

    getFreeSwitchNumber() {
        return this.http.get<number>('api/Portfolio/GetFreeSwitchNumber'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    isPortfolioVisible() {
        return this.http.get<boolean>('api/Portfolio/IsPortfolioVisible'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getSwitchDesinvestList() {
        return this.http.get<SwitchInvestmentProductModel[]>('api/Portfolio/GetSwitchDesinvestList'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getInProcessSwitch() {
        return this.http.get<InProcessSwitchModel[]>('api/Portfolio/GetInProcessSwitch'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getPortfolioValorisations() {
        return this.http.get<any>('api/Portfolio/GetPortfolioValorisations'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }



    getAllNavHistory(fndId: number) {
        return this.http.get<RepartitionChartLineModel[]>('api/Portfolio/GetAllNavHistory?fndId=' + fndId);
    }

    getPortfolioYields() {
        return this.http.get<any>('api/Portfolio/GetPortfolioYields'+ "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }



       //////////////////////
      // POST CALLZ
     //////////////////////////

    /**
        the name of the variable must be identical to the name in the core model
    */

    saveInvestmentRepartitionProductList(investmentProductList: InvestmentRepartitionProduct[]) {

        return this.http.post<any>('api/Portfolio/SaveInvestmentRepartitionProductList',
            {
                "productRepartitionList" : investmentProductList,
                "affId" : sessionStorage.getItem("STORAGE_AFFID")
            }
        );
    }

    getSwitchInvestmentList(desinvestIprList: number[]) {
        return this.http.post<SwitchInvestmentProductModel[]>('api/Portfolio/GetSwitchInvestmentList',
            {
                "iprArr" : desinvestIprList,
                "affId" : sessionStorage.getItem("STORAGE_AFFID")
            }
        
        );
    }

    saveSwitch(finalList: InvestmentProductModel[]) {
        return this.http.post<InvestmentProductModel[]>('api/Portfolio/SaveSwitch',
            {
                "investmentProductList" : finalList,
                "affId" : sessionStorage.getItem("STORAGE_AFFID")
            }
        );
    }


}
