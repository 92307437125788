
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core'; // TEST
import { BenefitsResultModel } from '../../models/benefits-result-model';
import { ErrorHandlerService } from '../../error-handler.service';
import { HomeService } from '../../core/middleware-call/home.service';

import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { RetirementActurialDatasResultModel } from '../../models/retirement-acturial-datas-result-model';
import { RiskActuarialDatasResultModel } from '../../models/risk-actuarial-datas-result-model';
//import { DatePipe } from '@angular/common'; // datepoc
//import { registerLocaleData } from '@angular/common'; // datepoc
//import localeFr from '@angular/common/locales/fr'; // datepoc
//registerLocaleData(localeFr); // datepoc

import { map } from 'rxjs/operators';


// Guarantees.Component = SECTION 1A - Plan View

@Component({
    selector: 'app-cover',
    templateUrl: './cover.component.html',
    styleUrls: ['./cover.component.scss']
})

export class CoverComponent implements OnInit {

    ////////////////
    //// DECLARATIONS
    //////////////////
    invalidityCoverHasExoneration: boolean; // cant name hasXXX, its forbidden by convention as double id, so name changed to something more explicit
    benefits: BenefitsResultModel[]; // used to store the plans we recieve from mid for this user to select from
    riskActuarialDatas: RiskActuarialDatasResultModel[]; // used to store the plans we recieve from mid for this user to select from
    retirementActurialDatasPopup: RetirementActurialDatasResultModel[];
    retirementActurialDataUnavailable: boolean;
    // date poc for input
    //public dob: string;
    //public pipe = new DatePipe('fr');
    // end date poc


    isBusy: boolean; // behaviorable subject subscribed through headerSharingService, used to condition pointer on hover and click availablility if something else is in progress

    constructor(
        config: NgbModalConfig, // initial, by Julien? Why MODAL? (its not a popup)
        private modalService: NgbModal, // allows the function for modal popups to be called
        private http: HttpClient,
        private formBuilder: FormBuilder,
        @Inject('BASE_URL') baseUrl: string,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private headerSharingService: HeaderSharingService,
        private homeService: HomeService,
        public errorHandler: ErrorHandlerService) {
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit() {
        console.log("cover comp constructor");

        // version 1 - popup data loaded only when user clicks on loupe
        this.getBenefits().then(res => {
            if(this.benefits.length>0)
                localStorage.setItem("PLAN_CUR_CODE", this.benefits[0].curCode);
            else
                localStorage.setItem("PLAN_CUR_CODE", "");
        });
        this.headerSharingService.viewIsBusy.subscribe(value => {
            this.isBusy = value;
        })
    }

    /**
    * Gathers data for the guarantee table on page load
    */
    getBenefits() {
        return new Promise((resolve, reject) => {
            this.homeService.getBenefits().subscribe(result => {
                console.log(" [gB0] GetBenefits - cover.comp");
                console.log(result);
                this.benefits = result;
                resolve('OK');
            }, error => {
                console.error(error);
                console.error(error.status);
                if (error.status == 500) {
                    console.error(" [6] handle 500");
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });
        });
    }

    determinIfInvalidityCoverHasExoneration() {
        this.homeService.hasExoneration().subscribe(
            result => {
                console.log(" hasExoneration");
                //console.log(result);
                this.invalidityCoverHasExoneration = result;
            }, error => {
                console.log("hasExoneration issue");
                console.error(error);
            }
        );
    }

    /** Executed if the getBenefits result array contains a bentype==I and gathers the data for the popup (invalidity) */
    getRiskActuarialDatas(benType) {

        // clean cover data to prevent flashes
        this.riskActuarialDatas=null;

        this.homeService.getRiskActuarialDatas(benType).subscribe(result => {
            console.log(" getRiskActuarialDatas");
            console.log(result);
            this.riskActuarialDatas = result;
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                console.error(" %i:Risk2% handle 500");
                this.errorHandler.handleError(error);
            }
        });
    }


    /** EXPERIMENTAL VERSION FOR MAPPING NESTED MODEL VALUES
     * /
           sealSearch(term: string): Observable<DeiInstance[]> {
              return this.http.get(this.sealUrl + term)
                .map(response => response.json() as DeiInstance[])
                .catch(this.handleError);
           }
     */
    getRetirementActurialDatas() {
        this.homeService.getRetirementActuarialDatas()
            .pipe(map(data => {
                this.retirementActurialDatasPopup = data;
            }))
            .subscribe(result => {
            }, error => {
                console.error(error);
                console.error(error.status);
                //if (error.status == 500) {
                //    console.error(" pipe $r:Retirement2$ handle 500");
                //    this.errorHandler.handleError(error);
                //}
                this.retirementActurialDataUnavailable = true;
            })
    }

    /**
     * allows to open modal popup on loupeclcik in section 1
     * @param content - holds the data needed to find the according modal code in the template
     * @param benType - allows distinction for what modal data to gather from backend
     */
    open(content,benType) {

        // do promise get data - then containing modal open
        console.log("open triggered : content = ");
        console.log(content);

        if (benType == "D") {
            console.log("launch gather data for benytpe D");
            this.getRiskActuarialDatas("D");
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        }
        if (benType == "I") {
            console.log("launch gather data for benytpe I");
            this.getRiskActuarialDatas("I");
            this.determinIfInvalidityCoverHasExoneration();
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        }
        if (benType == "R") {
            console.log("launch gather data for benytpe R");
            this.getRetirementActurialDatas();
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        }
        //this.modalService.open(content); // small popup centered on button

    }
    // commands for popup modal openings on Angular for different benTypes
    // seems to forward the params as is from template [contentR , contentI, contentD]



    openVerticallyCentered(content) {
        this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
    }
    closeModal() {
        this.modalService.dismissAll();
    }

    // for debug purpouse assuring angular respects async or syncronous calls depending on situation
    timeoutTester() {
        let t = 0;
        for (let timer = 0; timer < 1000000000; timer++) {
            if (timer % 100000000 == 0) { t++; console.log("debug flow-respect timer " + t); }
        }
    }

    //////////////////////////////
    // CUSTOM TOOLTIP DATA FUNCTION - DEVEXPRESS CHARTS
    /////////////////////////////////////////////////////////////

    customizeTooltip(arg) {
        //return {
        //  text: "Fund " + (arg.index + 1) + " - " + arg.valueText + " Euro"
        //};
        return {
            html: "<div> </div>"
        };
    }
}



