import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeLangModel } from '../../models/change-lang-model';
import { LancodeResultModel } from '../../models/lancode-result-model';
import { RoleResultModel } from '../../models/role-result-model';
import { ProfileDataModel } from '../../models/profile-data-model';
import { ProfileResultModel } from '../../models/profile-result-model';
import { ChangePasswordModel } from '../../models/change-password-model';
import { CompanyNameModel } from '../../models/company-name-model';
import { ForgotPasswordModel } from '../../models/forgot-password-model';
import { TokenChangePasswordModel } from '../../models/token-change-password-model';


@Injectable()
export class SharedService {

    constructor(private http: HttpClient) {

    }

    changeLang(lang: ChangeLangModel) {
        return this.http.post<string>('api/Shared/ChangeLang', lang);
    }

    getCompanyName() {
        return this.http.get<CompanyNameModel>('api/Home/GetCompanyName');
    }

    getLang() {
        return this.http.get<LancodeResultModel>('api/Shared/GetLang');
    }

    getRole() {
        return this.http.get<RoleResultModel>('api/Shared/GetRole');
    }

    getCountries() {
        return this.http.get<any>('api/Shared/GetCountries');
    }

    getProfileInformation() {
        return this.http.get<ProfileDataModel>('api/Shared/GetProfileInformation');
    }

    getAffiliateDocuments() {
        return this.http.get<any[]>('api/Shared/GetAffiliateDocuments' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    getBeneficiaryCertificatFromStream(clause: string) {
        const url = 'api/Aff/GetBeneficiaryCertificatFromStream' + "?clause=" + clause + "&affId=" + sessionStorage.getItem("STORAGE_AFFID");
        return this.http.get(url, { responseType: 'blob' });
    }

    getICAvailableExercices() {
        return this.http.get<any[]>('api/Aff/GetICAvailableExercices' + "?affId=" + sessionStorage.getItem("STORAGE_AFFID"));
    }

    updateProfileInformation(newProfileDataModel: ProfileResultModel) {
        return this.http.post<any>('api/Shared/UpdateProfileInformation', newProfileDataModel);
    }

    // this is the service that should have beed used (shared and not aff...)
    acceptConditions() {
        return this.http.post<any>('api/Shared/AcceptConditions', null);
    }

    changePassword(changePasswordModel: ChangePasswordModel) {
        return this.http.post<ChangePasswordModel>('api/Shared/ChangePassword', changePasswordModel)
    }

    getFileFromStream(bobId) {
        const url = 'api/Shared/GetFileFromStream' + "?bobId=" + bobId;
        return this.http.get(url, { responseType: 'blob' });
    }

    getExoFromStream(exeId) {
        const url = 'api/Shared/GetExoFromStream' + "?exeId=" + exeId + "&affId=" + sessionStorage.getItem("STORAGE_AFFID");
        return this.http.get(url, { responseType: 'blob' });
    }

    setCurrentAffiliateScheme(affId: number) {
        return this.http.post<string>('api/Aff/SetCurrentAffiliateScheme', affId);
    }

    forgotPassword(forgotPasswordModel: ForgotPasswordModel) {

        // @JKA What is that ?
        //let header = new HttpHeaders();
        //header= header.append('content-type', 'application/json');

        return this.http.post<any>('api/Login/ForgotPassword', forgotPasswordModel);
    }

    // for upload and download RH docs; categories (comment from eBenefits...)
    getDocumentTypes() {
        return this.http.get<any>('api/Shared/GetDocumentTypes');
    }

    resetPassword(tokenChangePassword: TokenChangePasswordModel) {
        return this.http.post<TokenChangePasswordModel>('api/Shared/ResetPassword', tokenChangePassword);
    }

}
