import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { TutorialsComponent } from '../shared/tutorials/tutorials.component';

import { BrowserModule } from '@angular/platform-browser';
import { RHGuardService } from './services/rh-guard.service';
import { AffiliateGuardService } from './services/affiliate-guard.service';

/*
 *  Core routing = conductor of the routing
 *
 *  It's role is to redirect a user to the right module
 *  - A disconnected user can only access the public module
 *  - An affiliate can access the affiliate module
 *  - A RH can access the rh module
 *  
 */
const CoreRoutes: Routes = [

    {
        path: 'rh',
        //Call a service which will return true or false depending on if the user is allowed to load the rh module
        canLoad: [RHGuardService],
        loadChildren: '../rh/rh.module#RHModule' 
    },
    {
        path: 'affiliate',
        //Call a service which will return true or false depending on if the user is allowed to load the affiliate module
        canLoad: [AffiliateGuardService],
        loadChildren: '../affiliate/affiliate.module#AffiliateModule'
    },
    {
        path: 'public',
        loadChildren: '../public/public.module#PublicModule'
    },
    {
        path: 'login',
        loadChildren: '../login/login.module#LoginModule'
    },
    // Default route if the URL isn't matching with previous ones
    {
        path: '**',
        redirectTo: '/public'
    }]

@NgModule({
    imports: [RouterModule.forRoot(CoreRoutes, { onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class CoreRoutingModule {
    constructor() { }
}
