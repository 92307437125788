//import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { CoreModule } from './core/core.module';

import { BrowserModule , Title} from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { ErrorHandlerService } from './error-handler.service';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { CookieService } from 'ngx-cookie-service';

// Used to set the german currency format in switch component (and probably others)
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);


/*
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
*/

// translation
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

// header refresh service
import { HeaderSharingService } from './core/services/header-sharing.service';
import { SidebarSharingService } from './core/services/sidebar-sharing.service';

import { AuthService } from './core/services/auth.service';
import { HomeService } from './core/middleware-call/home.service';
import { LoginService } from './core/middleware-call/login.service';
import { AffService } from './core/middleware-call/aff.service';
import { SharedService } from './core/middleware-call/shared.service';
import { PortfolioService } from './core/middleware-call/portfolio.service';
import { RHGuardService } from './core/services/rh-guard.service';
import { AffiliateGuardService } from './core/services/affiliate-guard.service';
import { UserConditionsGuardService } from './affiliate/services/user-conditions-guard.service';
import { UserConditionsFineService } from './affiliate/services/user-conditions-fine.service';
import { RHService } from './core/middleware-call/rh.service';
import { ToastrModule } from 'ngx-toastr';
import { AngularResizedEventModule } from 'angular-resize-event';
// custom pipe
import { PurgeInnerHtmlPipe } from './custom-pipes/purge-inner-html.pipe';
import { PurgeInnerHtmlPipeModule } from './custom-pipes/purge-inner-html.module';

//dev extreme
//import { DxButtonModule } from "devextreme-angular";
//import { DxChartModule } from "devextreme-angular";

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, 'i/', '-lang.json');
}

@NgModule({
    declarations: [
        AppComponent,
       // PurgeInnerHtmlPipe  // TEST1 v1
    ],
    imports: [
        //PurgeInnerHtmlPipeModule,  // TEST1 v2

        ToastrModule.forRoot(),
        //HttpModule, // Deprecated since angular 4 and will be breaking change in angular 8 // removed on 4/12/19 jka
        MatSnackBarModule,
        BrowserAnimationsModule,
        CoreModule, ReactiveFormsModule, AngularFontAwesomeModule, NgbModule, //30.1.19
        //DxButtonModule, DxChartModule, 
        HttpClientModule,
        Ng2SearchPipeModule,
        FormsModule,
        MatTableModule,
    AngularResizedEventModule, // poc for headerbar height detection resizes etc
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        Title, // for tab titles
       // PurgeInnerHtmlPipe,  // TEST1 v1
        ErrorHandlerService, UserConditionsGuardService,
        UserConditionsFineService, HeaderSharingService, SidebarSharingService,
        AuthService, RHGuardService, AffiliateGuardService, LoginService, SharedService,
        PortfolioService, AffService, RHService, HomeService,
        CookieService, { provide: LOCALE_ID, useValue: 'fr-FR' }],
    bootstrap: [AppComponent],
    exports: [
        //PurgeInnerHtmlPipe,  // TEST1 v1
    ]
    })
export class AppModule { }
