import { Component, HostListener } from '@angular/core';
import { UserInformationsChecksService } from '../../shared/services/user-informations-checks.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../error-handler.service';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProfileDataModel } from '../../models/profile-data-model';
import { ChangePasswordModel } from '../../models/change-password-model'
import { ProfileResultModel } from '../../models/profile-result-model';
import { CountriesModel } from '../../models/countries-model';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../core/middleware-call/shared.service';
import { ToastrService } from 'ngx-toastr';
import { routerTransition } from '../../router.animations';
import { HeaderSharingService } from '../../core/services/header-sharing.service';
import { ComponentCanDeactivate } from '../../core/services/pending-changes-guard';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AffService } from '../../core/middleware-call/aff.service';
import * as moment from 'moment';



@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [routerTransition()]
})

export class ProfileComponent implements ComponentCanDeactivate {
     @HostListener('window:beforeunload')
        canDeactivate(): Observable<boolean> | boolean {
        return this.canNavigate;
    }

    profileDataModel: ProfileDataModel;
    ProfileResultModel: ProfileResultModel;
    newProfileDataModel: ProfileResultModel;
    changePasswordModel: ChangePasswordModel;
    httpErrorResponse: HttpErrorResponse;
    countriesList: Array<CountriesModel>;
    role: string;
    public dob: string; // c est ce qui simule une entre de ton form (dans le link tu trouvera une version form)
    public pipe = new DatePipe('fr');
    public canNavigate: boolean = true;
    public riskProfileAvailable: boolean;

    public hasErrorOccured: boolean = false; // This is shit: shit name; wrong label (procs rule for pw composition label on generic error? WTF)
    public newPwAndConfirmPwAreDifferent: boolean = false;
    public oldPwEqualNewPw: boolean = false;
    public regexNotRespected: boolean = false;
    public fieldsMissing: boolean = false;

    profileDataForm = this.formBuilder.group({

        lastName: [''],
        firstName: [''],
        street: [''],
        postalCode: [''],
        cityName: [''],
        couCode: [''],
        birthdate: [''],
        maritalStatus: [''],
        childrenNb: [''],
        entryDate: [''],
        email: [''],
        phone: [''],
        mobile: [''],
        fax: [''],
    });

    passwordForm = this.formBuilder.group({
        oldPw: [''],
        newPw: [''],
        confirmPw: ['']
    })

    // Constructor
    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private http: HttpClient,
        public errorHandler: ErrorHandlerService,
        private headerSharingService: HeaderSharingService,
        private router: Router,
        private modalService: NgbModal,
        private sharedService: SharedService,
        public translate: TranslateService,
        public affService: AffService,
        public checkServices: UserInformationsChecksService) {

            this.headerSharingService.role.subscribe(result => {
                this.role = result;
            });
            this.getCountries();
            this.getProfileData().then(res => {
                //this.createprofileDataForm();
                this.fillInInitialFormData();

            });

            this.isRiskProfileAvailable(); // this will always return false for amazon
    }


    ngOnInit() {
        // prevent savage scroll on routing 
        document.body.scrollTop = 0;
    }


    // check if RiskProfile Wizard button shoul dbe shown
    isRiskProfileAvailable(){
        this.riskProfileAvailable = false; // amazon
        /*
                console.log("[1] isRiskProfileAvailable result");
                this.affService.isRiskProfileAvailable().subscribe(result => {
                    console.log("[2] isRiskProfileAvailable result");
                    console.log(result);
                    this.riskProfileAvailable = result;
                }, error => {
                    console.log("[3] isRiskProfileAvailable err");
                    this.riskProfileAvailable = false;
                });
        */
    }

    // Display profile data from the ProfileDataModel
    // Create the form and bind it to the ChangeProfileDataModel

    // Function to get the ProfileDataModel
    getProfileData() {
        console.log("[1] entering getProfileData");
        return new Promise((resolve, reject) => {
            this.sharedService.getProfileInformation().subscribe(result => {
                console.log("[2] GetProfileData result");
                console.log(result);
                this.profileDataModel = result;

                console.log(this.profileDataModel.birthdate);

                let v = 2;

            // v1 workaround : increment the day by one if leap year, but could fail on 1-1-1972 leapyears...
                if (v ==1){
                    let newDate = moment.utc(this.profileDataModel.birthdate).local();
                    if (newDate.isLeapYear()) newDate.date(newDate.date() +1)  ;
                    this.dob = this.pipe.transform(newDate, 'dd/MM/yyyy');
                }
            // v2 workaround : parse and trim the timezone off the strange dateformat as it seems to be the root of all evil
                else
                {
                    let splitBdate = this.profileDataModel.birthdate.toString().split('T')[0];
                    console.log(splitBdate);
                    this.dob = this.pipe.transform(splitBdate, 'dd/MM/yyyy');
                }

                resolve('OK');

//  1972-07-25T00:00:00+02:00

            }, error => {
                console.error("[2] error on callback GetProfileData");
                console.error(error);
                this.errorHandler.handleError(error);
            });
        });
    }

    // Function to put data taken from the middleware in the form
    fillInInitialFormData() {
        console.log("1 fillInInitialFormData: this.profileDataForm");
        console.log(this.profileDataForm);
        this.profileDataForm.patchValue({
            lastName: this.profileDataModel.lastName,
            firstName: this.profileDataModel.firstName,
            street: this.profileDataModel.street,
            postalCode: this.profileDataModel.postalCode,
            cityName: this.profileDataModel.cityName,
            couCode: this.profileDataModel.couCode,
            birthdate: this.profileDataModel.birthdate,
            maritalStatus: this.profileDataModel.maritalStatus,
            childrenNb: this.profileDataModel.childrenNb,
            entryDate: this.profileDataModel.entryDate,
            email: this.profileDataModel.email,
            phone: this.profileDataModel.phone,
            mobile: this.profileDataModel.mobile,
            fax: this.profileDataModel.fax
        })
        console.log(this.profileDataForm.get("street").value);
    }


    // onSubmit : TODO : make the checks before allowing the user to submit the form
    // Send data to middleware and wait for result
    validateChanges() {
        console.log(this.profileDataForm.get("street").value);
        this.newProfileDataModel = {
            street: this.profileDataForm.get("street").value,
            cityName: this.profileDataForm.get("cityName").value,
            postalCode: this.profileDataForm.get("postalCode").value,
            couCode: this.profileDataForm.get("couCode").value,
            fax: this.profileDataForm.get("fax").value,
            mobile: this.profileDataForm.get("mobile").value,
            phone: this.profileDataForm.get("phone").value,
            email: this.profileDataForm.get("email").value
        }
        console.log(this.newProfileDataModel);

        // this service responds with ok and has a message that should result in action or toast
        this.sharedService.updateProfileInformation(this.newProfileDataModel).subscribe(
            result => {
                this.canNavigate = true;
                console.log("t155 pct");
                console.log(result);
                // if we cant log the next stuff then we can act on the fact there is something wrong with the save
                if ( result == "No Data has been saved"){
                    this.toastr.error(this.translate.instant('TS_Data_Save_Fail'), '');
                    console.log("tFAIL");
                    if ( result.toString().indexOf("Token Not Found") > 0 ){
                        // session has timed out - moving this handling to middleware
                        console.log("token is timed out");
                    }
                }
                else{
                    this.toastr.success(this.translate.instant('TS_Data_Saved'), '');
                    console.log("tSUCCESS");
                }
            },
            error => {
                this.errorHandler.handleError(error);
                this.toastr.error(this.translate.instant('TS_Data_Save_Fail'), '');
            });
    }

    // GetCountries function
    getCountries() {
        this.sharedService.getCountries().subscribe(
            result => {
                this.countriesList = result;
            },
            error => {
                console.error("error on callback GetCountries");
                console.error(error);
                this.errorHandler.handleError(error);
            });
    }

    // open the change password modal
    open(content) {
        // First we close the currently opened modal if there is one
        this.modalService.dismissAll();
        // do promise get data - then containing modal open
        console.log("open triggered : content = ");
        console.log(content);
        this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
    }


    closeModal() {
        console.log("close modal executed");
        this.modalService.dismissAll();
    }

    // checks passwords form before sending it to MW
    changePassword() {
        console.log("changePassword log");

        this.hasErrorOccured = false; // This is shit: shit name; wrong label (procs rule for pw composition label on generic error? WTF)
        this.newPwAndConfirmPwAreDifferent = false; // invalid if true
        this.oldPwEqualNewPw = false; // invalid if true
        this.regexNotRespected = false; // invalid if true
        this.fieldsMissing = false;


        // invalidate check 1: are old and new pw equal
        if (this.passwordForm.get("oldPw").value == this.passwordForm.get("newPw").value) this.oldPwEqualNewPw = true;

        // invalidate check 2: are new pw and confirm pw different
        if (this.passwordForm.get("newPw").value != this.passwordForm.get("confirmPw").value)  this.newPwAndConfirmPwAreDifferent = true;

        // invalidate check 3 : is regex not respected
        const regex = new RegExp('((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*?[&@#(){}+=?!;:]).{8,})')
        if (this.passwordForm.get("newPw").value.match(regex) && this.passwordForm.get("confirmPw").value.match(regex)){
            console.log('both passwords match the regex');
        } else {
            this.regexNotRespected = true;
            console.log('one or both passwords dont match the regex');
        }
        

        // evaluation of the previous checks
        if (!this.oldPwEqualNewPw && !this.newPwAndConfirmPwAreDifferent && !this.regexNotRespected){
            // all invalidating conditions are false, so its all good for save
            this.changePasswordModel = new ChangePasswordModel();
            this.changePasswordModel.oldPassword = this.passwordForm.get("oldPw").value;
            this.changePasswordModel.newPassword = this.passwordForm.get("newPw").value;
            this.changePasswordModel.confirmPassword = this.passwordForm.get("confirmPw").value;

            this.sharedService.changePassword(this.changePasswordModel).subscribe(
                result => {
                    this.closeModal();
                    this.toastr.success(this.translate.instant('TS_Password_Changed_Success_Toast'), '');
                },
                error => {
                    this.toastr.error(this.translate.instant('TS_Password_Changed_Fail_Toast'), '');
                    this.errorHandler.handleError(error); // this should go to error page, so why does hasErrorOccured even exist??? leving it in for testing of error page for now...
                    this.hasErrorOccured = true;
                });

        }

    }


    goToRP(){
        this.router.navigate(['/affiliate/riskProfile/']);
    }



}
