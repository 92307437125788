import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { CoreRoutingModule } from './core-routing.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './services/auth.service';
import { AffiliateGuardService } from './services/affiliate-guard.service';
import { RHGuardService } from './services/rh-guard.service';

// HEADER BAR STUFF FROM https://stackblitz.com/edit/responsive-menu-angular-material-flex-layout?file=src/app/app.component.html
/*
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule } from "@angular/material";
*/
//import { FlexLayoutModule } from "@angular/flex-layout";


@NgModule({
    imports: [
/*
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
*/
    //FlexLayoutModule,
        CommonModule,
        CoreRoutingModule,
        AngularFontAwesomeModule,
        SharedModule,
        TranslateModule,
        NgbDropdownModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthService, AffiliateGuardService, RHGuardService
    ],
    declarations: [NotFoundComponent]
})
export class CoreModule { }
