import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../error-handler.service';

import { UserInformationsChecksService } from '../../shared/services/user-informations-checks.service';
import { AffService } from '../../core/middleware-call/aff.service';
import { routerTransition } from '../../router.animations';
import { RegisterEmailModel } from '../../models/register-email-model';

@Component({
    selector: 'app-register-email',
    templateUrl: './register-email.component.html',
    styleUrls: ['./register-email.component.scss'],
    animations: [routerTransition()]
})
export class RegisterEmailComponent implements OnInit {


    /*
     *  RegisterEmaail component = regitser email page
     *
     *  Once the user is logged, he will be redirect to this page if his email needs to be entered
     *  The redirection is made by the user-informations-checks.service in shared/services
     *  
     */

    emailForm: FormGroup;
    httpErrorResponse: HttpErrorResponse;
    register: string = "";
    sameAddress: boolean;
    registerEmail: RegisterEmailModel;

    constructor(
        private formBuilder: FormBuilder,
        private checkServices: UserInformationsChecksService,
        private http: HttpClient,
        private affService: AffService,
        private checkService: UserInformationsChecksService,
        public errorHandler: ErrorHandlerService
    ) {

        // We first check if conditions that must be filled before regitsering an email are fine
        if (!this.checkService.canAccessRegisterEmail()) {
            this.checkService.accountValidityCheck();
        }
    }

    initEmailForm() {
        this.emailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            confirmEmail: ['', [Validators.required, Validators.email]]
        })
    }

    ngOnInit() {
        this.initEmailForm();
    }

    onSubmit() {
        if (this.emailForm.get("email").value != this.emailForm.get("confirmEmail").value)
            this.sameAddress = true;
        else {
            //this.register = this.emailForm.get("email").value; // old
            this.registerEmail = new RegisterEmailModel(); // new
            this.registerEmail.email = this.emailForm.get("email").value; // new

            //this.affService.setEmailAddress(this.register).subscribe( // old
            this.affService.setEmailAddress(this.registerEmail).subscribe(
                result => {
                    console.log(result);
                    this.checkServices.accountValidityCheck();
                },
                error => {
                    this.httpErrorResponse = error;
                    this.errorHandler.handleError(this.httpErrorResponse);
                });
        }
    }
}
