import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
//we import that to don't have security problems with DOM
import { saveAs } from 'file-saver/src/FileSaver';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../../error-handler.service';
import { AffiliateDocModel } from '../../models/affiliate-doc-model';
import { AvailableExercicesModel } from '../../models/available-exercices-model';
import { SharedService } from '../../core/middleware-call/shared.service';
import { BeneficiaryDocModel } from '../../models/beneficiary-doc-model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-quick-actions',
    templateUrl: './quick-actions.component.html',
    styleUrls: ['./quick-actions.component.scss']
})



export class QuickActionsComponent implements OnInit {

//DEPRECATED COMPONENT


    /////////////////////////////////////////////
    /// Declarations
    /////////////////////////////////////////////
    // max / julien stuff?
    beneficiariesForm: FormGroup; // view data
    file: string;
    fileUrl;

    beneficiariesModalTextInputData: string = '';

    // modal downloadable data lists
    beneficiaryCertificateRequestData:BeneficiaryDocModel//gets data from button1 modal beneficiary clause mapped for request to mid
    availableExercises: AvailableExercicesModel[]; // button 2 content
    affDox: AffiliateDocModel[];// button 3 content
    // conditions the button 'Imprimer mon extrait de compte' - if false then deactivate button
    //printAccountStatementIsAvailable: boolean;  // SPECIFIC BALOISE


    initBeneficiariesForm() {
      console.log("----- init certif Form ------");
        // max / julien stuff?
        this.beneficiariesForm = this.formBuilder.group({
            certificateArea: ['']
        })
    }




    /////////////////////////////////////////////
    /// Constructor injections & onInit       ///
    /////////////////////////////////////////////
    constructor(
        private http: HttpClient,
        private formBuilder: FormBuilder,
        private config: NgbModalConfig,
        private modalService: NgbModal,
        private sharedService: SharedService,
        private toastr: ToastrService,
        public translate: TranslateService,
        public errorHandler: ErrorHandlerService
    ) {

        // max / julien stuff?    
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit() {
        // max / julien stuff?
      this.initBeneficiariesForm();



    }

    /////////////////////////////////////////////
    /// Class Methods
    /////////////////////////////////////////////

    ///////////////
    /////// POPUPS   ////
    //////////////////

    openVerticallyCentered(content) {
        this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
    }
    openAffDox(content) {
        // make the data call
        this.getAffiliateDocuments().then(res => {
            console.log("[getAffiliateDocuments] then part ,res = " + res);
            // then open the modal
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        });
    }
    openAvailableExercices(content) {
        // make the data call
        this.getICAvailableExercices().then(res => {
            console.log("[availableExercises] then part ,res = " + res);
            // then open the modal
            this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        });
    }
    /*
        this.getFundModalDetails(fndId).then(res => {
          console.log("[4] then part ,res = " + res);
          this.modalService.open(content, { centered: true, size: 'lg', backdrop: true , keyboard:true });
        });
    */



    // max / julien stuff?
    closeModal() {
        this.modalService.dismissAll();
    }

    ///////////////
    /////// MIDDLEWARE CALLS  ////
    ///////////////////////////

    // button 1 Designer/Modifier mes beneficiaires - getExerciseContent()
  generateBeneficiariesPdf(clause: string) {
      this.sharedService.getBeneficiaryCertificatFromStream(clause).subscribe((data) => {

        saveAs(data, this.translate.instant('TS_Beneficiaries_Certif_File_Name') + "_" + ''); // should not need the .pdf at the end as it's most certainly included via mid/backend

      }, error => {
        console.error(error);
        console.error(error.status);
        if (error.status == 500) {
          console.error("generateBeneficiariesPdf handle 500");
          this.errorHandler.handleError(error);
        }
    });
      // make the call to GetBeneficiaryCertificatFromStream


      this.closeModal();
    }

    // button 2 (Imprimer mon certificat) - get available Exercises
    getICAvailableExercices() {
        return new Promise((resolve, reject) => {
            this.sharedService.getICAvailableExercices().subscribe(result => {
                console.log("getICAvailableExercices");
                // @MAX - fais une liste avec ce resultat et au click sur un de ces elements lance le download
                // IF this result is null (or empty array), then disable the button
                console.log(result);
                this.availableExercises = result;
                resolve('OK');

            }, error => {
                console.error(error);
                console.error(error.status);
                if (error.status == 500) {
                    console.error("[3]  GetAffiliateDocuments handle 500");
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });
        });
    }

    getExerciseContent(exeId) {
        this.toastr.success('', this.translate.instant('TS_Document_Generation_Started_Toast'));
        console.log("getExerciseContent with exeId:" + exeId);
        this.modalService.dismissAll();
        this.sharedService.getExoFromStream(exeId).subscribe((data) => {
            saveAs(data, this.translate.instant('TS_Certificat') + "_" +exeId + "_" + sessionStorage.getItem("STORAGE_AFFID") + '.pdf');
        }, error => {
            console.error(error);
            console.error(error.status);
            if (error.status == 500) {
                this.toastr.warning('', this.translate.instant('TS_Document_Generation_Fail_Toast'));
            }
        });
    }

    // Button #3's (Consulter les documents du regime de pension) modal data
    getAffiliateDocuments() {
        return new Promise((resolve, reject) => {
            this.sharedService.getAffiliateDocuments().subscribe(result => {
                console.log(result);

                this.affDox = result;
                resolve('OK');

            }, error => {
                console.error(error);
                console.error(error.status);
                if (error.status == 500) {
                    console.error("[3]  GetAffiliateDocuments handle 500");
                    this.errorHandler.handleError(error);
                }
                resolve('NOK');
            });
        });

    }



    // Button #3's (Consulter les documents du regime de pension) modal document list - onClick trigger - downloads PDF
    getDocumentContent(bobId, fileName) {
        console.log("getDocumentContent with bobid:" + bobId + " and filename : " + fileName);
        this.modalService.dismissAll();
        this.sharedService.getFileFromStream(bobId).subscribe((data) => {
            //saveAs(data, fileName + '.pdf');
            saveAs(data, fileName + '');
        }, error => {
            console.error(error);
            this.toastr.warning('', this.translate.instant('TS_Document_Generation_Fail_Toast'));
        });
    }




}
