import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RoleResultModel } from '../../models/role-result-model';
import { BehaviorSubject } from 'rxjs';
import { ErrorHandlerService } from '../../error-handler.service';

@Injectable()
export class AuthService {

    checkRole: RoleResultModel;

    myRole: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    role: string;

    constructor(private http: HttpClient,
        public errorHandler: ErrorHandlerService) {
    }


    isUserRH() {
        if (this.myRole.getValue() == "RH") {
            return true;
        }
        return false;
    }

    isUserAffiliate() {
        if (this.myRole.getValue() == "AFF") {
            return true;
        }
        return false;
    }

    /* Not used anymore, since canLoad needs a promise as return (see getAsyncRole below)
    * - Also this does not return a role, but sets the myrole var in auth.svc
    * - This myRole var will later be checked by isAff */
    getRole() {
        console.log("getRole function exec");
        this.http.get<RoleResultModel>('api/Shared/GetRole').subscribe(
            result => {
                this.myRole.next(result.role);
            },
            error => {
                console.log("error");
                this.errorHandler.handleError(error);
            });
    }

    /**
     * This is used via the injection in the AffiliateGuardservice
     * NB: does not return role but sets myRole var in auth.svc */
    getAsyncRole(): Promise<any> {
        console.log("getAsyncRole function exec");
        return this.http.get<RoleResultModel>('api/Shared/GetRole').toPromise().then(
            result => {
                this.myRole.next(result.role);
            },
            error => {
                console.log("error");
                this.errorHandler.handleError(error);
            });
    }
}
