import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { TutorialsComponent } from './tutorials/tutorials.component';
import { UserData } from '../models/user-data';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PlanViewComponent } from './plan-view/plan-view.component';
import { FundsComponent } from './funds/funds.component';
import { CoverComponent } from './cover/cover.component';
import { InvestementPolicyComponent } from './investement-policy/investement-policy.component';
import { VariousComponent } from './various/various.component';
import { QuickActionsComponent } from './quick-actions/quick-actions.component';
import { WalletComponent } from './portfolio/portfolio.component';
import { ProfileComponent } from './profile/profile.component';
import { CurrencyPipe } from '@angular/common';
import { PendingChangesGuard } from '../core/services/pending-changes-guard';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDatepickerModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

// DEVEX CHARTS
import { DxLinearGaugeModule, DxChartModule, DxChartComponent, DxPieChartModule, DxRangeSelectorModule } from 'devextreme-angular';

// Tab Syncronization
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { MatButtonModule, MatTooltipModule } from '@angular/material';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GeneralConditionsComponent } from './general-conditions/general-conditions.component';
import { GeneralConditionsReviewComponent  } from './general-conditions-review/general-conditions-review.component';
import { RegisterEmailComponent } from './register-email/register-email.component';

import { PurgeInnerHtmlPipeModule } from '../custom-pipes/purge-inner-html.module';


@NgModule({
    declarations: [
TutorialsComponent,
ChangePasswordComponent,
        GeneralConditionsComponent,GeneralConditionsReviewComponent,
        RegisterEmailComponent,
PlanViewComponent,
FundsComponent,
CoverComponent,
InvestementPolicyComponent,
QuickActionsComponent,
WalletComponent,
ProfileComponent,
VariousComponent],
    imports: [
PurgeInnerHtmlPipeModule,  // TEST2 v2
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgxsModule,
        NgxsReduxDevtoolsPluginModule,
        NgxsFormPluginModule,
        DxChartModule,
        DxPieChartModule,
        DxRangeSelectorModule,
        AngularFontAwesomeModule,
        MatTableModule,
        MatPaginatorModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatSelectModule,
        MatTooltipModule,
        MatExpansionModule,
        NgbCollapseModule,
        DxLinearGaugeModule
    ],
    exports: [

    ],
    providers: [CurrencyPipe,PendingChangesGuard]
})
export class SharedModule { }
