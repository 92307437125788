

 import { NgModule }      from '@angular/core';
 import { PurgeInnerHtmlPipe }      from '../custom-pipes/purge-inner-html.pipe';


 @NgModule({
     imports:        [],
     declarations:   [PurgeInnerHtmlPipe],
     exports:        [PurgeInnerHtmlPipe],
 })

 export class PurgeInnerHtmlPipeModule {

   static forRoot() {
      return {
          ngModule: PurgeInnerHtmlPipeModule,
          providers: [],
      };
   }
 } 

